import { FetchSuccessfulPaymentsResponse } from "../../../fetchPayments";
import {
  formatAmount,
  formatDate,
  formatPaymentMethod,
} from "../../../../../utils";
import { PaymentUploadStatus } from "../../../../../constants";
import { IPaymentsOverview } from "../types";

export const successfulPaymentsResponseToPaymentsAdapter = ({
  searchRetailPayments: data,
}: FetchSuccessfulPaymentsResponse): IPaymentsOverview => {
  const { entities, totalEntityCount } = data;

  const parsedEntities = entities.map((entity) => ({
    id: entity.id,
    uploadTimestamp: formatDate(entity.createdOn, true),
    accountingDate: formatDate(entity.accountingDate),
    paymentMethod: formatPaymentMethod(entity.type),
    externalId: entity.externalId,
    uploadStatus: PaymentUploadStatus.SUCCESSFUL,
    amount: entity.amount
      ? formatAmount(
          entity.amount.amountMinorUnits,
          entity.amount.currencyCode,
          true
        )
      : "-",
    accountHolder: entity.accountDetails?.holder,
    orderId: entity.orderId,
    orderNumber: entity.orderNumber,
    country: entity.countryCode,
    action: {
      paymentId: entity.id,
      externalId: entity.externalId,
      orderId: entity.orderId,
    },
    comments: entity.comments,
  }));

  return {
    entities: parsedEntities,
    totalEntityCount,
  };
};
