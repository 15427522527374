import {
  SuccessfulPayment,
  OtherPayment,
  UnidentifiedPayment,
} from "~/adapters";
import { PaymentType } from "~/constants";

import { callGql } from "../../../utils/apiClient";

import { gqlConfig } from "./constants";

export type PaymentByType = {
  successful: SuccessfulPayment;
  internal: OtherPayment;
  unidentified: UnidentifiedPayment;
};

export const fetchPaymentDetails = async <T extends PaymentType>(
  paymentType: T,
  paymentId: string,
  orderId?: string
): Promise<PaymentByType[T]> => {
  const { query, domain, adapter } = gqlConfig[paymentType];

  const { data, errors } = await callGql({
    query,
    variables: orderId ? { paymentId, orderId } : { paymentId },
    domain,
  });

  if (errors?.length) {
    throw errors[0].exception?.localizedMessage;
  }

  return adapter(data) as PaymentByType[T];
};
