import { useMemo } from "react";

import { Permission } from "../constants";

import { useHasPermissions } from "./useHasPermissions";

export function useCheckPermissions(permissions: Permission | Permission[]) {
  const hasPermissions = useHasPermissions();

  return useMemo(
    () => hasPermissions(permissions),
    [permissions, hasPermissions]
  );
}
