import { HTMLAttributes, ReactNode } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  value?: ReactNode;
  label?: ReactNode;
}

export const OptionalInfoRow = ({ value, label, ...props }: Props) => {
  return value ? (
    <div {...props}>
      {label ? (
        <>
          {label}
          {": "}
        </>
      ) : null}
      {value}
    </div>
  ) : null;
};
