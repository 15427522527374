import { DownPricingConfigs } from '@features/OverviewPage/DownPricingConfig';
import {
  useGetDictionariesQuery,
  useGetDownPricingConfigsQuery,
} from '@gql_codegen/price-management-config-types';
import {
  createFileRoute,
  ErrorRouteComponent,
  useRouter,
} from '@tanstack/react-router';
import { Button, notification } from 'antd';
import { formatServerError } from '@src/shared/formating/format-server-error';
import { ComponentProps, useEffect } from 'react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';

const ErrorComponent: ErrorRouteComponent = ({
  error,
}: ComponentProps<ErrorRouteComponent>) => {
  const router = useRouter();
  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    // Reset the query error boundary
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  return (
    <div>
      {formatServerError(error)}
      <Button
        onClick={() => {
          // Invalidate the route to reload the loader, and reset any router error boundaries
          void router.invalidate();
        }}
      >
        Retry
      </Button>
    </div>
  );
};

const DownPricingRouteComponent = () => {
  return <DownPricingConfigs />;
};

export const Route = createFileRoute(
  '/$lang/price-management-config/downpricing-config/',
)({
  component: DownPricingRouteComponent,
  loader: ({ context: { queryClient } }) => {
    void Promise.all([
      queryClient.ensureQueryData({
        revalidateIfStale: true,
        queryFn: useGetDownPricingConfigsQuery.fetcher(),
        queryKey: useGetDownPricingConfigsQuery.getKey(),
      }),
      queryClient.ensureQueryData({
        queryFn: useGetDictionariesQuery.fetcher(),
        queryKey: useGetDictionariesQuery.getKey(),
      }),
    ]);
  },
  errorComponent: ErrorComponent,
  onCatch: (error) => {
    notification.error({
      message: 'Failed to load page',
      description: formatServerError(error),
    });
  },
});
