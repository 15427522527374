import { useState } from 'react';
import { InputNumber, Switch } from 'antd';
import { useFormContext, FieldPath } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { DownpricingConfigSchemaType } from '../../model/downpricingConfigSchema';
import { HStack } from '@styled-system/jsx';

type DeltaAmountProps = {
  isCarvana: boolean;
  formFieldsPath: FieldPath<DownpricingConfigSchemaType>;
};

export const DeltaAmount: React.FC<DeltaAmountProps> = ({
  isCarvana,
  formFieldsPath,
}) => {
  const {
    control,
    unregister,
    register,
    // , getFieldState
  } = useFormContext<DownpricingConfigSchemaType>();

  const [isCarvanaMode, toggleMode] = useState(isCarvana);

  return (
    <>
      <Switch
        checkedChildren="Carvana"
        unCheckedChildren="Percentage"
        size="small"
        checked={isCarvanaMode}
        onChange={() => {
          toggleMode(!isCarvanaMode);

          if (!isCarvanaMode) {
            unregister(
              `${formFieldsPath}.deltaPercentage` as FieldPath<DownpricingConfigSchemaType>,
            );
            register(
              `${formFieldsPath}.deltaAmount1` as FieldPath<DownpricingConfigSchemaType>,
              { value: 0 },
            );
            register(
              `${formFieldsPath}.deltaAmount2` as FieldPath<DownpricingConfigSchemaType>,
              { value: 0 },
            );
          } else {
            unregister(
              `${formFieldsPath}.deltaAmount1` as FieldPath<DownpricingConfigSchemaType>,
            );
            unregister(
              `${formFieldsPath}.deltaAmount2` as FieldPath<DownpricingConfigSchemaType>,
            );
            register(
              `${formFieldsPath}.deltaPercentage` as FieldPath<DownpricingConfigSchemaType>,
              { value: 0 },
            );
          }
        }}
        data-qa-selector="modeSwitch"
      />

      {isCarvanaMode ? (
        <HStack justifyContent="flex-start" alignItems="flex-start">
          <FormItem
            control={control}
            name={
              `${formFieldsPath}.deltaAmount1` as FieldPath<DownpricingConfigSchemaType>
            }
            required
          >
            <InputNumber
              style={{
                width: '100%',
                // , background: getFieldState(`${formFieldsPath}.deltaAmount1` as FieldPath<DownpricingConfigSchemaType>).isDirty ? '#ffff00' : '#fff'
              }}
              placeholder="amount1"
              controls={false}
              min={0}
              max={999}
              size="small"
              data-qa-selector="deltaAmount1"
            />
          </FormItem>
          <FormItem
            control={control}
            name={
              `${formFieldsPath}.deltaAmount2` as FieldPath<DownpricingConfigSchemaType>
            }
            required
          >
            <InputNumber
              style={{
                width: '100%',
                // , background: getFieldState(`${formFieldsPath}.deltaAmount2` as FieldPath<DownpricingConfigSchemaType>).isDirty ? '#ffff00' : '#fff'
              }}
              placeholder="amount2"
              controls={false}
              min={0}
              max={999}
              size="small"
              data-qa-selector="deltaAmount2"
            />
          </FormItem>
        </HStack>
      ) : (
        <FormItem
          control={control}
          name={
            `${formFieldsPath}.deltaPercentage` as FieldPath<DownpricingConfigSchemaType>
          }
          required
        >
          <InputNumber
            style={{
              width: '50%',
              // , background: getFieldState(`${formFieldsPath}.deltaPercentage` as FieldPath<DownpricingConfigSchemaType>).isDirty ? '#ffff00' : '#fff'
            }}
            placeholder="%"
            controls={false}
            min={0}
            max={100}
            size="small"
            data-qa-selector="deltaPercentage"
          />
        </FormItem>
      )}
    </>
  );
};
