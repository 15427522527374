import { useState } from "react";
import { Button, Form, Modal, Select, Typography } from "antd";

import { OtherPaymentCategory } from "~/constants";

import { paymentOverviewSelectors } from "../../constants";
import { useOtherPaymentCategoryOptions } from "../hooks";

import { useMarkUnidentifiedPaymentAsOther } from "./useMarkUnidentifiedPaymentAsOther";

const { Text } = Typography;
const TITLE = "Mark as other payment";

interface MarkAsOtherPaymentModalProps {
  orderId: string;
  isVisible: boolean;
  paymentId: string;
  onCancel: () => void;
}

export const MarkAsOtherPaymentModal = (
  props: MarkAsOtherPaymentModalProps
) => {
  const { orderId, isVisible, paymentId, onCancel } = props;
  const [selectedCategory, setSelectedCategory] =
    useState<OtherPaymentCategory>();
  const markPaymentAsInternal = useMarkUnidentifiedPaymentAsOther(paymentId);

  const otherPaymentCategoryOptions = useOtherPaymentCategoryOptions();

  const onSubmit = () => {
    if (selectedCategory) {
      markPaymentAsInternal.mutate(selectedCategory);
    }
  };

  const handleCancel = () => {
    setSelectedCategory(undefined);
    onCancel();
  };

  return (
    <Modal
      destroyOnClose
      title={TITLE}
      visible={isVisible}
      onOk={onSubmit}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          onClick={handleCancel}
          data-qa-selector={paymentOverviewSelectors.markAsInternalCancelBtn}
        >
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          disabled={!selectedCategory}
          loading={markPaymentAsInternal.isLoading}
          onClick={onSubmit}
          data-qa-selector={paymentOverviewSelectors.markAsInternalConfirmBtn}
        >
          Confirm
        </Button>,
      ]}
    >
      <p>You are about to mark payment {orderId} as other</p>
      <Form layout="vertical">
        <Form.Item required label={<Text>Category</Text>} name="country">
          <Select<OtherPaymentCategory>
            style={{ width: "100%" }}
            size="large"
            placeholder="Select category"
            onSelect={setSelectedCategory}
            options={otherPaymentCategoryOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
