import { useQuery } from "react-query";

import { OtherPaymentCategory } from "~/constants";

import { labelByOtherPaymentType } from "../../constants";
import { callGql } from "../../utils/apiClient";

const otherPaymentOptionsQuery = `query {
    internalPaymentOptions {
        categories
    }
}`;

const fetchOtherPaymentOptions = async (): Promise<OtherPaymentCategory[]> => {
  const { data } = await callGql({
    query: otherPaymentOptionsQuery,
  });

  return data.internalPaymentOptions.categories;
};

export const useOtherPaymentCategoryOptions = () => {
  const { data } = useQuery("otherPaymentOptions", fetchOtherPaymentOptions, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: Infinity,
  });

  return data?.map((op) => ({
    label: labelByOtherPaymentType[op],
    value: op,
  }));
};
