import { Tabs } from 'antd';
import { TAB_NAMES } from '@src/constants/tabs';
import { useLocation, useRouter } from '@tanstack/react-router';
import type { TabsProps } from 'antd';

const tabItems: TabsProps['items'] = [
  {
    key: TAB_NAMES.TRACK_DISTRIBUTION_CONFIG,
    label: 'Track Distribution Config',
  },
  {
    key: TAB_NAMES.DOWNPRICING_CONFIG,
    label: 'Downpricing Config',
  },
];

export const OverviewPage = () => {
  const page = useLocation().pathname.split('/')[3];
  const router = useRouter();
  const activeTabKey = page ?? TAB_NAMES.TRACK_DISTRIBUTION_CONFIG;

  const onTabChange = (activeTab: string) => {
    router
      .navigate({
        to: `/$lang/price-management-config/${activeTab}`,
        params: { lang: 'en' },
      })
      .catch((e: unknown) => {
        console.log(
          '[OverviewPage::onTabChange => Failed to navigate to unknown tab',
          e,
        );
      });
  };

  return (
    <>
      <Tabs
        type="card"
        destroyInactiveTabPane={true}
        activeKey={activeTabKey}
        onChange={onTabChange}
        items={tabItems}
      />
    </>
  );
};
