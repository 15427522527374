export const CommentFragment = /* GraphQL */ `
  fragment CommentFragment on RetailPaymentCommentProjection {
    id
    value
    createdBy {
      firstName
      lastName
    }
    createdOn
  }
`;
