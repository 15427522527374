import { Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { useMemo, Key } from "react";

import { Allow, Comments } from "~/components";
import {
  PaymentType,
  Permission,
  paymentsOverviewSelectors,
} from "~/constants";

import { PaymentOverviewEntity } from "../hooks";
import { useCurrentPaymentType } from "../../../pages/hooks";

import { getPaymentsOverviewColumns } from "./getPaymentsOverviewColumns";
import { PaymentActionsColumn } from "./PaymentActionsColumn";

const commentsPermByType = {
  [PaymentType.Successful]: Permission.querySuccessfulPaymentsComments,
  [PaymentType.Internal]: Permission.queryInternalPaymentsComments,
  [PaymentType.Unidentified]: Permission.queryUnidentifiedPaymentsComments,
};

interface PaymentsOverviewTableProps {
  data: Array<PaymentOverviewEntity>;
  page: number | string;
  loading: boolean;
  setSelectedRowKeys: (keys: Key[]) => void;
}

const PaymentsOverviewTable = ({
  loading,
  data,
  page,
  setSelectedRowKeys,
}: PaymentsOverviewTableProps) => {
  const paymentType = useCurrentPaymentType();
  const columns = useMemo(() => {
    const actionColumn: ColumnType<PaymentOverviewEntity> = {
      dataIndex: "action",
      key: "action",
      title: "Action",
      render: (_, { id, externalId, orderId }) => (
        <PaymentActionsColumn
          qaSelector={paymentsOverviewSelectors.action}
          page={page}
          paymentId={id}
          externalId={externalId}
          orderId={orderId}
        />
      ),
    };

    return [...getPaymentsOverviewColumns(paymentType), actionColumn];
  }, [page, paymentType]);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };
  return (
    <Table
      loading={loading}
      bordered
      dataSource={data}
      pagination={false}
      size="small"
      rowKey="id"
      scroll={{ x: true }}
      style={{ marginBottom: "5px" }}
      expandable={{
        expandedRowRender: (payment) => (
          <>
            <Allow perm={commentsPermByType[paymentType]}>
              <Comments paymentType={paymentType} payment={payment} />
            </Allow>
          </>
        ),
      }}
      columns={columns}
      rowSelection={rowSelection}
    />
  );
};

export default PaymentsOverviewTable;
