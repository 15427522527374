import { Button, Form, Space } from "antd";

import { UnidentifiedPayment } from "~/adapters";

import { PaymentOverviewCard } from "../../components";
import { useHasPermissions } from "../../hooks";
import { UnidentifiedEditPermission } from "../../constants";
import { UnidentifiedPaymentStatus } from "../PaymentsOverview/PaymentsOverviewFilter";

import { useUpdateUnidentifiedPayment } from "./useUpdateUnidentifiedPayment";
import { MarkAsOtherPaymentButton } from "./MarkAsOtherPaymentButton";
import { PutPaymentOnHoldButton } from "./PutPaymentOnHoldButton";

type UnidentifiedPaymentEditFormProps = {
  payment: UnidentifiedPayment;
};
export const UnidentifiedPaymentEditForm = ({
  payment,
}: UnidentifiedPaymentEditFormProps) => {
  const [form] = Form.useForm<UnidentifiedPayment>();
  const hasPermission = useHasPermissions();
  const canUpdatePayment = hasPermission(
    UnidentifiedEditPermission.updateUnidentifiedPayment
  );
  const canMarkAsOtherPayment = hasPermission(
    UnidentifiedEditPermission.markUnidentifiedPaymentAsOther
  );
  const updatePaymentMutation = useUpdateUnidentifiedPayment(payment?.id);

  return (
    <Form
      form={form}
      initialValues={payment}
      onFinish={updatePaymentMutation.mutate}
    >
      <PaymentOverviewCard
        mode={canUpdatePayment ? "edit" : "view"}
        payment={payment}
        editableFields={[
          "paymentType",
          "customerReference",
          "accountHolderName",
          "bic",
          "iban",
        ]}
      />
      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        {canUpdatePayment && (
          <Button
            type="primary"
            htmlType="submit"
            disabled={updatePaymentMutation.isLoading}
            loading={updatePaymentMutation.isLoading}
          >
            Save
          </Button>
        )}
        {canMarkAsOtherPayment && (
          <MarkAsOtherPaymentButton
            paymentId={payment?.id}
            orderId={payment?.externalId}
          />
        )}
        {payment.status !== UnidentifiedPaymentStatus.OnHold ? (
          <PutPaymentOnHoldButton disabled={!payment} paymentId={payment?.id} />
        ) : null}
      </Space>
    </Form>
  );
};
