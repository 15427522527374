import { useEffect, useState } from "react";
import { Table } from "antd";

import { Comment } from "~/adapters";
import { PaymentType, Permission } from "~/constants";

import { Allow } from "../Allow";

import { CommentForm } from "./CommentForm";
import { useTableColumns } from "./hooks";

const createPermByType = {
  [PaymentType.Successful]: Permission.createSuccessfulPaymentComment,
  [PaymentType.Internal]: Permission.createInternalPaymentComment,
  [PaymentType.Unidentified]: Permission.createUnidentifiedPaymentComment,
};

interface CommentsProps {
  paymentType: PaymentType;
  payment: {
    id: string;
    comments: Comment[];
  };
}

export function Comments({ paymentType, payment }: CommentsProps) {
  const [comments, setComments] = useState(() => payment.comments);
  const columns = useTableColumns();

  useEffect(() => {
    setComments(payment.comments);
  }, [payment.comments]);

  return (
    <>
      <Allow perm={createPermByType[paymentType]}>
        <CommentForm
          paymentType={paymentType}
          paymentId={payment.id}
          commentsSize={comments.length}
          onSuccess={(newComment) =>
            setComments((comments) => [...comments, newComment])
          }
        />
      </Allow>
      <Table dataSource={comments} columns={columns} />
    </>
  );
}
