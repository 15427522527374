import { CommentFragment } from "~/graphql";

export const otherPaymentsQuery = /* GraphQL */ `
  query ($search: SearchProjection2Input!) {
    searchInternalTransferPayments(search: $search) {
      totalEntityCount
      entities {
        accountData {
          holder
          bic
          iban
        }
        accountingDate
        amount {
          amountMinorUnits
          currencyCode
        }
        countryCode
        createdOn
        externalId
        id
        type
        comments {
          ...CommentFragment
        }
      }
    }
  }
  ${CommentFragment}
`;
