export type PhasesKeys = 'fresh' | 'turnover1' | 'turnover2' | 'offloading';
// type TableColOptions = { key: TableColOptionsKeys; title: string };
// export const tableColOptions = [
//   { key: 'fresh', title: 'fresh' },
//   { key: 'turnOver1', title: 'turnover1' },
//   { key: 'turnOver2', title: 'turnover2' },
//   { key: 'offloading', title: 'offloading' },
// ] as const satisfies readonly TableColOptions[];

export const phasesTableRowConfig = {
  minOnlineDays: {
    title: 'Min online days',
    fieldType: 'number',
  },
  maxOnlineDays: {
    title: 'Max online days',
    fieldType: 'number',
  },
  lagDays: {
    title: 'Lag days',
    fieldType: 'number',
  },
  killSwitchDays: {
    title: 'Kill switch days',
    fieldType: 'number',
  },
  ignoreOpenOpportunities: {
    title: 'Ignored open opportunities',
    fieldType: 'boolean',
  },
  targetPriceRatio: {
    title: 'Target price ratio [%]',
    fieldType: 'number',
  },
} as const;

export type TableRowKeys = keyof typeof phasesTableRowConfig;

export const deltasTableRowConfig = {
  q1Config: {
    title: 'Q1',
  },
  q2Config: {
    title: 'Q2',
  },
  q3Config: {
    title: 'Q3',
  },
  q4Config: {
    title: 'Q4',
  },
} as const;

export type DeltasTableRowKeys = keyof typeof deltasTableRowConfig;
