import {
  methodsMap,
  unidentifiedPaymentMethodsMap,
} from "../utils/paymentMethod";

import { CreationSource } from "./creationSource";
import { OtherPaymentCategory } from "./otherPaymentCategory";

export const DEFAULT_PAGE = 1;
export const PAGE_SIZE = 50;

export const ERROR_MESSAGE = "Oops! Something went wrong.";

export enum PaymentUploadStatus {
  SUCCESSFUL = "Successful",
  UNIDENTIFIED = "Unidentified",
}

export const paymentMethodsOptions = Object.entries(methodsMap).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const creationSourceOptions = Object.entries(CreationSource).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const unidentifiedPaymentMethodsOptions = Object.entries(
  unidentifiedPaymentMethodsMap
).map(([value, label]) => ({
  value,
  label,
}));

export const labelByOtherPaymentType: Record<OtherPaymentCategory, string> = {
  [OtherPaymentCategory.Chargeback]: "Chargeback",
  [OtherPaymentCategory.ChequePaymentsBatch]: "Cheque Payments Batch",
  [OtherPaymentCategory.DuplicateFinancingAmount]: "Duplicate Financing Amount",
  [OtherPaymentCategory.FinancingKickback]: "Financing Kickback",
  [OtherPaymentCategory.Internal]: "Internal",
  [OtherPaymentCategory.Legal]: "Legal",
  [OtherPaymentCategory.Logistic]: "Logistic",
  [OtherPaymentCategory.NotApplicable]: "Not applicable payment",
  [OtherPaymentCategory.OnHold]: "On hold",
  [OtherPaymentCategory.OutgoingRefund]: "Outgoing Refund",
  [OtherPaymentCategory.Overpayment]: "Overpayment",
  [OtherPaymentCategory.PenaltyFees]: "Penalty Fees",
  [OtherPaymentCategory.RatenkaufFinancing]: "Ratenkauf financing",
  [OtherPaymentCategory.Refund]: "Refund",
  [OtherPaymentCategory.RejectedOutbound]: "Rejected outbound payment",
  [OtherPaymentCategory.Test]: "Test",
};

export const otherPaymentTypeByLabel = (
  Object.keys(labelByOtherPaymentType) as OtherPaymentCategory[]
).reduce<Record<string, OtherPaymentCategory>>((acc, otherPaymentType) => {
  acc[labelByOtherPaymentType[otherPaymentType].toLowerCase()] =
    otherPaymentType;
  return acc;
}, {});
