import { Collapse } from 'antd';
import { useMemo } from 'react';
import type { CollapseProps } from 'antd';
import { PhaseConfigTable } from './phaseConfigTable';
import type { ClusterType } from '../../store/downPricingStore';

type ClusterCollapseProps = {
  country: string;
  cluster: ClusterType;
  setEditedTracksCount: React.Dispatch<React.SetStateAction<number>>;
};

export const ClusterCollapse: React.FC<ClusterCollapseProps> = ({
  country,
  cluster,
  setEditedTracksCount,
}) => {
  const trackItems: CollapseProps['items'] = useMemo(() => {
    return cluster.trackConfigs.map((track, idx) => {
      return {
        key: idx,
        style: { background: track.isTrackEdited && '#ffff00' },
        label: `Experiment track ${track.track}`,
        ['data-qa-selector']: `trackCollapseItem-${idx}`,
        children: (
          <>
            <PhaseConfigTable
              phaseConfigs={track.phaseConfigs}
              country={country}
              cluster={cluster.priceCluster}
              track={track.track}
              setEditedTracksCount={setEditedTracksCount}
            />
          </>
        ),
      };
    });
  }, [cluster, country, setEditedTracksCount]);

  return (
    <Collapse data-qa-selector="clusterCollapseWrapper" items={trackItems} />
  );
};
