import { OrderCustomerModel } from "@/types/OrderDocumentDetailsModel";
import { formatDate } from "@/utils/datetime";
import { CUSTOMER_TYPES } from "@retail/order-constants";
import { useTranslation } from "react-i18next";
import { OptionalInfoRow } from "./OptionalInfoRow";

interface Props {
  customer?: OrderCustomerModel;
}

export const CustomerInfo = ({ customer }: Props) => {
  const { t } = useTranslation();
  const {
    firstName,
    lastName,
    dateOfBirth,
    taxCode,
    type,
    customerId,
    customerType,
    name,
  } = customer ?? {};

  return (
    <section data-qa-selector="customerInfo">
      <OptionalInfoRow
        data-qa-selector="customerName"
        value={[firstName, lastName].filter(Boolean).join(" ")}
      />
      <OptionalInfoRow
        data-qa-selector="companyName"
        value={customerType === CUSTOMER_TYPES.COMPANY && name}
        label={t("bo.orderDocuments.viewer.orderDetails.companyName")}
      />
      <OptionalInfoRow
        data-qa-selector="customerDateOfBirth"
        value={formatDate(dateOfBirth)}
      />
      <OptionalInfoRow
        data-qa-selector="customerTaxId"
        value={taxCode}
        label={t("bo.orderDocuments.viewer.orderDetails.taxCode")}
      />
      <OptionalInfoRow
        data-qa-selector="type"
        value={type}
        label={t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.type"
        )}
      />
      <OptionalInfoRow
        data-qa-selector="customerId"
        value={customerId}
        label={t(
          "bo.ordersPage.tabs.consumerFinancing.externalFinancing.personalDetails.customerId"
        )}
      />
    </section>
  );
};
