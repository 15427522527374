import { usePageData } from '@hooks/usePageData';
import { useState } from 'react';
import {
  PublishData,
  useHasPublishingBlockers,
  useIsPublished,
} from '../publishHooks';
import { Table, Button, Popover } from 'antd';
import cns from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cn from './../styles.less';
import { formatDateTime } from '@utils/date';
import { Accounts } from './Accounts';
import {
  MARKETPLACES,
  POSSIBLE_PUBLISHING_STATUSES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import { renderTitle, renderDate } from '../functions';
import { useAppForm } from '@hooks/useAppForm';
import { usePublish, useUnpublish } from '../publishHooks';
import {
  ExportListingState,
  ExportListing,
  MarketplaceAccountOptions,
  ContentTemplate,
} from '@gql_codegen/retail-types';
import { Status, PublishStatusChecking } from './../index';
import { useCallback } from 'react';
import { useAnalytics } from '@hooks/useAnalytics';

const { Column } = Table;

type Autoscout24Props = {
  status: Status;
  setCommonPublishingStatus: PublishStatusChecking;
};

type As24AccountListingExtraProps = {
  isSelected: boolean;
  contentTemplate: ContentTemplate;
};
// TODO workaround to exclude deprecated fields
export type As24AccountListing = Omit<ExportListing, 'accountId' | 'state'> &
  As24AccountListingExtraProps;

export const Autoscout24 = (props: Autoscout24Props): JSX.Element | null => {
  const { status, setCommonPublishingStatus } = props;
  const { data } = usePageData();
  const { getValues } = useAppForm();
  const track = useAnalytics();
  const hasPublishingBlockers = useHasPublishingBlockers();
  const isPublished = useIsPublished(MARKETPLACES.AUTOSCOUT24);
  const [isPublishing, publish] = usePublish(setCommonPublishingStatus);
  const [isUnpublishing, unpublish] = useUnpublish(setCommonPublishingStatus);
  const [isAccountsHidden, setAccountsHidden] = useState(true);
  const exportItems = getValues('adMgmt.exportOverview.exportItems') ?? [];
  const autoscout24ExportData = exportItems.find(
    (item) => item.marketplace.id.toUpperCase() === MARKETPLACES.AUTOSCOUT24,
  );

  const as24AccoutOptions =
    data?.adMgmt.dictionaries.autoscout24?.marketplaceAccountOptions ?? [];
  const [as24accounts, setOptions] = useState(
    as24AccoutOptions.map<
      MarketplaceAccountOptions & As24AccountListingExtraProps
    >((account) => ({
      ...account,
      isSelected: false,
      contentTemplate: autoscout24ExportData?.exportListings?.listings.find(
        (listing) => listing.account.id === account.value,
      )?.contentTemplate ?? {
        id:
          account.contentTemplateOptions.find((template) => template.defaulted)
            ?.id ?? '',
      },
    })),
  );

  const autoscout24ExportDataMapped = autoscout24ExportData
    ? {
        marketplace: autoscout24ExportData.marketplace,
        daysOnline: autoscout24ExportData.daysOnline,
        firstPublishedAt: autoscout24ExportData.firstPublishedAt,
        updatedAt: autoscout24ExportData.updatedAt,
        lastPublishingErrors: autoscout24ExportData.lastPublishingErrors,
        publishingStatus: autoscout24ExportData.publishingStatus,
        exportListings: autoscout24ExportData.exportListings,
        canPublish: autoscout24ExportData.canPublish,
      }
    : null;

  const selectAS24Account = useCallback(
    (accountId: string) => {
      const accountsCopy = [...as24accounts];
      const foundAccount = accountsCopy.find((acc) => acc.value === accountId);

      if (foundAccount) {
        foundAccount.isSelected = !foundAccount.isSelected;
        setOptions(accountsCopy);
      }
    },
    [setOptions, as24accounts],
  );

  const selectAS24TemplateId = useCallback(
    (accountId: string, newTemplateId: string) => {
      const accountsCopy = [...as24accounts];
      const foundAccount = accountsCopy.find((acc) => acc.value === accountId);

      if (!foundAccount) return;

      foundAccount.contentTemplate.id = newTemplateId;
      setOptions(accountsCopy);
    },
    [setOptions, as24accounts],
  );

  if (!autoscout24ExportDataMapped) {
    return null;
  }

  const findSelectedAccountIds = () =>
    as24accounts.filter((acc) => acc.isSelected).map((acc) => acc.value);

  const handlePublishSelected = () => {
    const accountIds = findSelectedAccountIds();
    const data: PublishData = {
      marketplaceId: autoscout24ExportDataMapped.marketplace.id,
      accountIds,
      accountConfigs: as24accounts
        .filter((acc) => acc.isSelected)
        .map((account) => ({
          accountId: account.value,
          contentTemplateId: account.contentTemplate.id,
        })),
    };
    publish(data);
    track(
      {
        eventType: 'publish',
        fieldId: 'Regular publish',
        section: TRACKING_SECTION.EXPORT_OVERVIEW_CL,
        sectionCategory: TRACKING_SECTION_CATEGORY.CL,
      },
      data,
    );
  };

  const handleUnpublishSelected = () => {
    const accountIds = findSelectedAccountIds();
    const data = {
      marketplaceId: autoscout24ExportDataMapped.marketplace.id,
      accountIds,
    };
    unpublish(data);
    track(
      {
        eventType: 'unpublish',
        fieldId: 'Regular unpublish',
        section: TRACKING_SECTION.EXPORT_OVERVIEW_CL,
        sectionCategory: TRACKING_SECTION_CATEGORY.CL,
      },
      data,
    );
  };

  const toggleSelectAll = () => {
    const isAllSelected = as24accounts.every((acc) => acc.isSelected);
    const selectedAccounts = as24accounts.map((account) => ({
      ...account,
      isSelected: !isAllSelected,
    }));

    setOptions(selectedAccounts);
  };

  const dataSource = [autoscout24ExportDataMapped];

  const renderStatus = () => {
    const { lastPublishingErrors, publishingStatus } =
      autoscout24ExportDataMapped;

    if (
      (status.isStatusChecking &&
        status.platform.toUpperCase() === MARKETPLACES.AUTOSCOUT24) ||
      isPublishing ||
      isUnpublishing ||
      publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS
    ) {
      return (
        <div className={cn.status} data-qa-selector="autoscout24-inProgress">
          In Progress ...
        </div>
      );
    }

    if (isPublished) {
      return (
        <div
          className={cn.stateItem}
          data-qa-selector="autoscout24-notPublishedState"
        >
          Published
        </div>
      );
    }

    const hasErrors = !!(lastPublishingErrors && lastPublishingErrors.length);

    const publishingErrorTitle =
      lastPublishingErrors.length === 1
        ? `Last publishing error`
        : `Last ${lastPublishingErrors.length} publishing errors`;

    const lastErrors = lastPublishingErrors.map(
      ({ createdAt, message }, index) => (
        <div key={index}>
          {formatDateTime(createdAt)} | {message}
        </div>
      ),
    );

    return (
      <div className={cn.status}>
        {!isPublished && (
          <div
            className={cn.stateItem}
            data-qa-selector="autoscout24-notPublishedState"
          >
            Not Published
          </div>
        )}

        {hasErrors && (
          <Popover
            content={lastErrors}
            title={publishingErrorTitle}
            trigger="click"
            placement="bottom"
          >
            <div key="errors" className={cns(cn.stateItem, cn.hasErrors)}>
              {'Has errors '}
              <ExclamationCircleOutlined />
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const renderActionBtns = () => {
    const { canPublish, marketplace, publishingStatus } =
      autoscout24ExportDataMapped;
    const isBtnDisabled =
      !canPublish ||
      hasPublishingBlockers ||
      status.isStatusChecking ||
      isPublishing ||
      isUnpublishing ||
      publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS;
    const isAllSelected =
      as24accounts.length && as24accounts.every((acc) => acc.isSelected);
    const isSomeSelected = as24accounts.some((acc) => acc.isSelected);
    return (
      <div className={cn.actionWrapper}>
        <Button
          className={cn.actionButton}
          data-qa-selector={`selectAll-${marketplace.id}`}
          onClick={toggleSelectAll}
          type="primary"
          style={{ backgroundColor: '#ff7518', borderColor: 'transparent' }}
        >
          {isAllSelected ? 'Deselect All' : 'Select All'}
        </Button>
        <Button
          className={cn.actionButton}
          data-qa-selector={`publishSelected-${marketplace.id}`}
          onClick={handlePublishSelected}
          disabled={isBtnDisabled || !isSomeSelected}
          type="primary"
        >
          Publish Selected
        </Button>
        <Button
          className={cn.actionButton}
          data-qa-selector={`unpublishSelected-${marketplace.id}`}
          onClick={handleUnpublishSelected}
          type="primary"
          danger
          disabled={isBtnDisabled || !isSomeSelected}
        >
          Unpublish Selected
        </Button>
      </div>
    );
  };

  const renderExpandBtn = () => {
    return (
      <span
        className={cn.foldButton}
        data-qa-selector={'toggleAccountsListBtn'}
        onClick={() => {
          setAccountsHidden(!isAccountsHidden);
        }}
      >
        {isAccountsHidden ? (
          <span>&#9661; Show accounts</span>
        ) : (
          <span>&#9651; Hide accounts</span>
        )}
      </span>
    );
  };

  // TODO workaround to exclude deprecated fields
  const listings: Array<
    Omit<ExportListing, 'accountId' | 'state'> & {
      isSelected: boolean;
      contentTemplate: ContentTemplate;
    }
  > = as24accounts.map((item) => {
    const existingListing =
      autoscout24ExportDataMapped.exportListings?.listings.find(
        (listItem) => listItem.account.id === item.value,
      );

    const defaultTemplateId =
      item.contentTemplateOptions.find((option) => option.defaulted)?.id ?? '';
    if (existingListing) {
      return {
        ...existingListing,
        isSelected: item.isSelected,
        contentTemplate: {
          id: item.contentTemplate.id ?? defaultTemplateId,
        },
      };
    } else {
      return {
        contentTemplate: {
          id: defaultTemplateId,
        },
        daysOnline: null,
        firstPublishedAt: null,
        lastPublishedAt: null,
        lastPublishingErrors: [],
        publishingStatus: ExportListingState.Unpublished,
        account: { id: item.value, title: item.label },
        isSelected: item.isSelected,
      };
    }
  });

  const renderAccounts = () => {
    return (
      <Accounts
        exportListings={listings}
        selectAS24Account={selectAS24Account}
        selectAS24TemplateId={selectAS24TemplateId}
        status={status}
        setCommonPublishingStatus={setCommonPublishingStatus}
        canPublish={autoscout24ExportDataMapped.canPublish}
      />
    );
  };

  type ExportItem = typeof autoscout24ExportDataMapped;

  return (
    <div className={cn.root}>
      <Table<ExportItem>
        data-qa-selector="autoscout24-export-table"
        pagination={false}
        rowKey={'publishingStatus'}
        size="small"
        dataSource={dataSource}
        expandIconColumnIndex={5}
        scroll={{
          x: true,
        }}
      >
        <Column<ExportItem>
          dataIndex="marketplace"
          title={() =>
            renderTitle({ key: 'marketplace', title: 'Marketplace' })
          }
          render={(marketplace) => (
            <span data-qa-selector="marketplace-value">
              {marketplace.title}
            </span>
          )}
          width="10%"
        />
        <Column<ExportItem>
          dataIndex="firstPublishedAt"
          title={() =>
            renderTitle({
              key: 'firstPublishedAt',
              title: 'First Published At',
            })
          }
          render={(date: string) => (
            <span data-qa-selector={'firstPublishedAt-value'}>
              {renderDate(date)}
            </span>
          )}
          width="10%"
        />
        <Column<ExportItem>
          dataIndex="updatedAt"
          title={() => renderTitle({ key: 'updatedAt', title: 'Updated At' })}
          render={(date: string) => (
            <span data-qa-selector={'updatedAt-value'}>{renderDate(date)}</span>
          )}
          width="10%"
        />
        <Column<ExportItem>
          dataIndex="daysOnline"
          title={() => renderTitle({ key: 'daysOnline', title: 'Days Online' })}
          render={(daysCount: number) => (
            <span data-qa-selector={'daysOnline-value'}>{daysCount}</span>
          )}
          width="8%"
        />
        <Column<ExportItem>
          dataIndex="publishingStatus"
          title={() => renderTitle({ key: 'publishingStatus', title: 'State' })}
          render={renderStatus}
          width="10%"
        />
        <Column<ExportItem>
          dataIndex=""
          title={() => renderTitle({ key: 'city', title: 'City' })}
          render={renderExpandBtn}
        />
        <Column<ExportItem>
          dataIndex="_"
          title={() => renderTitle({ key: 'action', title: 'Action' })}
          render={renderActionBtns}
        />
      </Table>
      <div
        className={isAccountsHidden ? cn.accountsHidden : cn.accountsShown}
        data-qa-selector="as24-accounts-wrapper"
      >
        {renderAccounts()}
      </div>
    </div>
  );
};
