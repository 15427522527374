import { OtherPaymentCategory } from "~/constants";

import { Domain } from "../../../constants";
import { markUnidentifiedPaymentAsOtherMutation } from "../../../graphql";
import { callGql } from "../../../utils/apiClient";

export const markUnidentifiedPaymentAsOther = async (
  id: string,
  category: OtherPaymentCategory
) => {
  const variables = { id, category };

  const { data, errors } = await callGql({
    query: markUnidentifiedPaymentAsOtherMutation,
    variables,
    domain: Domain.PAYMENTS,
  });

  if (errors?.length) {
    throw errors[0]?.message;
  }

  return data?.transitionPaymentUnidentifiedToInternalTransfer;
};
