import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";

import { PaymentType } from "~/constants";

import { UpdatePaymentVM } from "../../components/PaymentOverviewCard";
import { updateRetailUnidentifiedPayment } from "../../graphql";
import { callGql } from "../../utils/apiClient";
import { Domain } from "../../constants";
import { usePaymentDetails } from "../hooks";

type UpdateUnidentifiedPaymentEntity = {
  accountHolder?: string;
  customerPaymentReference?: string;
  iban?: string;
  paymentType?: string;
  bic?: string;
  status?: string;
};

export const mapUnidentifiedPaymentToUpdateEntity = (
  payment: UpdatePaymentVM
): UpdateUnidentifiedPaymentEntity => ({
  accountHolder: payment.accountHolderName,
  customerPaymentReference: payment.customerReference,
  iban: payment.iban,
  status: payment.status,
  paymentType: payment.paymentType,
  bic: payment.bic,
});

const getFieldsToUpdate = <T extends Record<string, unknown>>(
  initialValues: T,
  newValues: T
): Partial<T> =>
  Object.entries(newValues).reduce(
    (prev, [key, newValue]) =>
      newValue === initialValues[key as keyof T]
        ? prev
        : { ...prev, [key]: newValue },
    {}
  );

export const useUpdateUnidentifiedPayment = (id?: string) => {
  const { data: initialValues } = usePaymentDetails(PaymentType.Unidentified);
  const queryClient = useQueryClient();

  return useMutation(
    async (payment: UpdatePaymentVM) => {
      const values = initialValues
        ? getFieldsToUpdate(
            mapUnidentifiedPaymentToUpdateEntity(initialValues),
            mapUnidentifiedPaymentToUpdateEntity(payment)
          )
        : mapUnidentifiedPaymentToUpdateEntity(payment);
      const { errors } = await callGql({
        query: updateRetailUnidentifiedPayment,
        variables: {
          payment: values,
          id,
        },
        domain: Domain.PAYMENTS,
      });

      if (errors?.length) {
        throw errors[0]?.message;
      }
      message.success("Payment was updated successfully");
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["paymentDetails", id]);
        queryClient.refetchQueries(["payments"]);
      },
    }
  );
};
