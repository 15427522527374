import { ReactNode } from "react";

import { Permission } from "~/constants";
import { useCheckPermissions } from "~/hooks";

interface AllowProps {
  perm: Permission | Permission[];
  children?: ReactNode;
}

export function Allow({ perm, children }: AllowProps) {
  return useCheckPermissions(perm) ? <>{children}</> : null;
}
