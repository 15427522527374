import { Button } from "antd";
import { useState } from "react";

import { UnidentifiedPayment } from "~/adapters";

import { DistributePaymentModal } from "./DistributePaymentModal";

export const DistributePaymentButton = ({
  payment,
}: {
  payment: UnidentifiedPayment | undefined;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const openDistributionModal = () => setIsVisible(true);
  const closeDistributionModal = () => setIsVisible(false);

  return (
    <>
      <Button type="primary" onClick={openDistributionModal}>
        Distribute
      </Button>
      <DistributePaymentModal
        onClose={closeDistributionModal}
        visible={isVisible}
        payment={payment}
      />
    </>
  );
};
