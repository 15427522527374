import { PermissionChecker, AccessDomain, useHasPermission } from "@/acl";
import { OrderFinancingType } from "@/generated/graphql";
import { Nil } from "@/types";
import { useQuery } from "react-query";

import { getApiClient } from "@/apiClient";
import { ClassificationOption } from "@/components/ClassificationFields/types";
import {
  DOCUMENT_CATEGORIES,
  DOCUMENT_TYPES,
  DOCUMENT_TOP_CATEGORY_ORDER,
} from "@/constants/documents";
import { Category, DocumentType } from "@/types/CategoryEntity";
import { DocumentEntity } from "@/types/DocumentEntity";
import { useTranslation } from "react-i18next";
import { FINANCING_TYPES } from "@retail/order-financing/src/constants";

interface UseCategoriesDataInput {
  retailCountry: string;
  financingType: Nil | OrderFinancingType;
  document?: DocumentEntity;
}

const isCategoryAllowed = ({
  financingType,
  category,
  hasPermission,
}: {
  financingType: Nil | string;
  category: Category;
  hasPermission: PermissionChecker;
}): boolean =>
  category.name !== DOCUMENT_CATEGORIES.FINANCE ||
  financingType !== FINANCING_TYPES.EXTERNAL ||
  hasPermission("externalFinancingDocumentOfCategoryFinancingEdit");

const isTypeAllowed = ({
  type,
  hasPermission,
}: {
  type: DocumentType;
  hasPermission: PermissionChecker;
}): boolean =>
  ![
    DOCUMENT_TYPES.DATA_PRIVACY,
    DOCUMENT_TYPES.KBA_APPROVAL_DECISION,
    DOCUMENT_TYPES.KBA_FEE_NOTICE,
    DOCUMENT_TYPES.KBA_TEMPORARY_REGISTRATION,
    DOCUMENT_TYPES.PROOF_OF_LICENSE_PLATE_RESERVATION,
    DOCUMENT_TYPES.SEPA_MANDAT,
  ].includes(type.key!) ||
  hasPermission("digitalCarRegistrationDocumentsViewEdit");

export const useCategoriesData = ({
  retailCountry,
  financingType,
  document,
}: UseCategoriesDataInput) => {
  const { t } = useTranslation();
  const hasPermission = useHasPermission();
  const query = useQuery(
    ["document-categories", retailCountry],
    () =>
      getApiClient({
        domain: AccessDomain.BackofficeManagement,
      }).LoadDocumentCategories({
        topCategory: DOCUMENT_TOP_CATEGORY_ORDER,
        country: retailCountry,
      }),
    {
      staleTime: 60 * 1000,

      select: ({ documentCategories }) => {
        const rawCategories = (documentCategories?.categories ??
          []) as Category[];

        const categories = rawCategories.map<ClassificationOption>(
          (category) => {
            const label = t(
              `bo.orderDocuments.documentCategory.${category.name}`
            );

            return {
              label,
              value: category.name!,
              disabled: !isCategoryAllowed({
                financingType,
                category,
                hasPermission,
              }),
              "data-qa-selector": "option",
              "data-qa-selector-option-label": label,
            };
          }
        );

        const typesByCategory = Object.fromEntries(
          rawCategories.map<[string, ClassificationOption[]]>((category) => [
            category.name!,
            category.documentTypes
              .filter((type) => isTypeAllowed({ type, hasPermission }))
              .map((type) => ({
                label: t(`bo.orderDocuments.documentType.${type.key}`),
                value: type.id,
                "data-qa-selector": "option",
                "data-qa-selector-option-key": type.key!,
                "data-qa-selector-option-label": type.name!,
              })),
          ])
        );

        const subTypesByType = Object.fromEntries(
          rawCategories.flatMap(({ documentTypes }) =>
            documentTypes.map<[string, ClassificationOption[]]>((type) => [
              type.id,
              type.subTypes?.map((subType) => ({
                label: t(`bo.orderDocuments.documentSubType.${subType!.key}`),
                value: subType!.key!,
                "data-qa-selector": "option",
                "data-qa-selector-option-label": subType!.name!,
              })) ?? [],
            ])
          )
        );

        if (document) {
          const currTypes =
            typesByCategory[
              document.documentType?.retailDocumentCategoryDTO?.name!
            ];

          if (Array.isArray(currTypes)) {
            const currType = currTypes.find(
              (x) => x.value === document.documentType?.id
            );

            if (!currType) {
              typesByCategory[
                document.documentType?.retailDocumentCategoryDTO?.name!
              ].push({
                label: t(
                  `bo.orderDocuments.documentType.${document.documentType?.key}`
                ),
                value: String(document.documentType?.id),
                disabled: true,
                "data-qa-selector": "option",
                "data-qa-selector-option-key": String(
                  document.documentType?.key
                ),
                "data-qa-selector-option-label": String(
                  document.documentType?.name
                ),
              });
            }
          }
        }

        return {
          categories,
          typesByCategory,
          subTypesByType,
        };
      },
    }
  );

  return {
    data: query.data,
    isLoading: query.isLoading,
  };
};
