import { FetchUnidentifiedPaymentsResponse } from "../../../fetchPayments";
import {
  formatAmount,
  formatDate,
  formatPaymentMethod,
} from "../../../../../utils";
import { PaymentUploadStatus } from "../../../../../constants";
import { IPaymentsOverview } from "../types";

export const unidentifiedPaymentsResponseToPaymentsAdapter = ({
  searchRetailUnidentifiedPayments: data,
}: FetchUnidentifiedPaymentsResponse): IPaymentsOverview => {
  const { entities, totalEntityCount } = data;

  const parsedEntities = entities.map((entity) => ({
    id: entity.id,
    uploadTimestamp: formatDate(entity.uploadDate, true),
    accountingDate: formatDate(entity.accountingDate),
    paymentMethod: formatPaymentMethod(entity.paymentData.paymentType),
    externalId: entity.externalId,
    uploadStatus: PaymentUploadStatus.UNIDENTIFIED,
    status: entity.status,
    amount: formatAmount(
      entity.paymentData.amount,
      entity.paymentData.currencyCode.description,
      true
    ),
    accountHolder: entity.paymentData.accountHolder,
    orderId: "",
    orderNumber: "",
    country: entity.paymentData.countryCode,
    action: {
      paymentId: entity.id,
      externalId: entity.externalId,
    },
    comments: entity.comments,
  }));

  return {
    entities: parsedEntities,
    totalEntityCount,
  };
};
