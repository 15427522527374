export enum Domain {
  ORDER = "order.management",
  PAYMENTS = "payments.import.management",
}

export enum ViewPermission {
  querySuccessfulPayments = "gqls://order.management/query/searchretailpayments",
  querySuccessfulPaymentsComments = "gqls://order.management/query/searchretailpayments/comments",
  querySuccessfulPayment = "gqls://order.management/query/retailpayment",
  querySuccessfulPaymentComments = "gqls://order.management/query/retailpayment/comments",
  queryInternalPayments = "gqls://payments.import.management/query/searchinternaltransferpayments",
  queryInternalPaymentsComments = "gqls://order.management/query/searchinternaltransferpayments/comments",
  queryInternalPayment = "gqls://payments.import.management/query/internaltransferpaymentbyid",
  queryInternalPaymentComments = "gqls://payments.import.management/query/internaltransferpaymentbyid/comments",
  queryUnidentifiedPayments = "gqls://payments.import.management/query/searchretailunidentifiedpayments",
  queryUnidentifiedPaymentsComments = "gqls://order.management/query/searchretailunidentifiedpayments/comments",
  queryUnidentifiedPayment = "gqls://payments.import.management/query/unidentifiedpaymentbyid",
  queryUnidentifiedPaymentComments = "gqls://payments.import.management/query/unidentifiedpaymentbyid/comments",
}

export enum SuccessfulEditPermission {
  updateSuccessfulPayment = "gqls://payments.import.management/mutation/updateretailpayment",
  createSuccessfulPaymentComment = "gqls://order.management/mutation/createretailpaymentcomment",
}

export enum InternalEditPermission {
  markInternalAsUnidentifiedPayment = "gqls://payments.import.management/mutation/transitionpaymentinternaltransfertounidentified",
  createInternalPaymentComment = "gqls://order.management/mutation/createinternaltransferpaymentcomment",
}

export enum UnidentifiedEditPermission {
  assignUnidentifiedPaymentToOrder = "gqls://payments.import.management/mutation/assignunidentifiedpaymenttoorder",
  markUnidentifiedPaymentAsOther = "gqls://payments.import.management/mutation/transitionpaymentunidentifiedtointernaltransfer",
  updateUnidentifiedPayment = "gqls://payments.import.management/mutation/updateretailunidentifiedpayment",
  changeRetailPaymentOrder = "gqls://payments.import.management/mutation/changeretailpaymentorder",
  transitionPaymentUnidentifiedToInternalTransferBatch = "gqls://payments.import.management/mutation/transitionpaymentunidentifiedtointernaltransferbatch",
  distributeUnidentifiedPayment = "gqls://payments.import.management/mutation/distributeunidentifiedpayment",
  createUnidentifiedPaymentComment = "gqls://order.management/mutation/createunidentifiedpaymentcomment",
}

export const Permission = {
  ...ViewPermission,
  ...SuccessfulEditPermission,
  ...InternalEditPermission,
  ...UnidentifiedEditPermission,
};
export type Permission =
  | ViewPermission
  | SuccessfulEditPermission
  | InternalEditPermission
  | UnidentifiedEditPermission;
