import { useCallback } from "react";
import { usePermissionsContext } from "./usePermissionsContext";

const permissionsMap = {
  internalFinancingDocumentOfTypeDirectDebitMandateView: [
    "gqls://document.management/query/internalfinancingsepapdfview",
  ],
  externalFinancingDocumentOfCategoryFinancingView: [
    "gqls://document.management/query/externalfinancingdocumentsview",
  ],
  externalFinancingDocumentOfCategoryFinancingEdit: [
    "gqls://document.management/mutation/externalfinancingdocumentsedit",
  ],
  internalFinancingDocumentOfTypeRiskScoreCardView: [
    "gqls://order.financing.risk.management/mutation/generatepdfdocument",
  ],
  digitalCarRegistrationDocumentsViewEdit: [
    "gqls://order.management/mutation/updatedigitalcarregistration",
  ],
};

export type Permission = keyof typeof permissionsMap;

export type PermissionChecker = ReturnType<typeof useHasPermission>;

export const useHasPermission = () => {
  const allowedPermissions = usePermissionsContext();

  return useCallback(
    (requestedPermissions: Permission[] | Permission) => {
      const permissions = Array.isArray(requestedPermissions)
        ? requestedPermissions
        : [requestedPermissions];

      return permissions.every((permission) =>
        permissionsMap[permission].every(
          (permissionId) => allowedPermissions[permissionId]
        )
      );
    },
    [allowedPermissions]
  );
};
