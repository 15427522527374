import { useMutation } from "react-query";

import { Comment } from "~/adapters";
import { PaymentType, Domain } from "~/constants";
import { callGql } from "~/utils/apiClient";

import { createPaymentCommentMutationByType } from "../graphql";
import { FormValues } from "../types";

interface HookParams {
  paymentType: PaymentType;
  paymentId: string;
  onSuccess: (comment: Comment) => void;
}

export function useCreateCommentMutation({
  paymentType,
  paymentId,
  onSuccess,
}: HookParams) {
  return useMutation({
    async mutationFn({ comment }: FormValues) {
      const { data, errors } = await callGql({
        query: createPaymentCommentMutationByType[paymentType],
        variables: {
          paymentId,
          value: comment,
        },
        domain: Domain.ORDER,
      });
      if (errors?.length) {
        throw errors[0]?.message;
      }
      return data?.comment;
    },
    onSuccess,
  });
}
