import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Divider } from "antd";

import { Permission, PaymentType } from "~/constants";
import { Allow, Comments } from "~/components";

import { OrderSearchFormVM } from "../../components/OrderMatchesSeachBar";
import { routePathByPage } from "../../constants";
import { OrderMatchesTable } from "../../components";
import { useQueryParams } from "../../hooks";
import {
  SUGGESTION_COLUMNS,
  SEARCH_COLUMNS,
} from "../../components/OrderMatchesTable/config";
import { useOrders, useSuggestions, usePaymentDetails } from "../hooks";
import { PaymentHeader } from "../PaymentHeader";

import { UnidentifiedPaymentEditForm } from "./UnidentifiedPaymentEditForm";

export const UnidentifiedPaymentEdit = () => {
  const { data: payment } = usePaymentDetails(PaymentType.Unidentified);
  const { data: suggestions, refetch: searchSuggestions } =
    useSuggestions(payment);
  const [queryParams, setQueryParams] = useQueryParams();
  const { orderNumber } = queryParams;
  const { data: orders = [] } = useOrders(orderNumber);
  useEffect(() => {
    if (payment) {
      searchSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  const prevState = useHistory().location.state;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { orderId, ...restQueryParams } = queryParams;
    setQueryParams(restQueryParams, prevState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrdersSearch = (values: OrderSearchFormVM) =>
    setQueryParams(
      {
        ...queryParams,
        ...values,
      },
      prevState
    );

  return payment ? (
    <>
      <PaymentHeader
        payment={payment}
        navigationPath={routePathByPage.unidentifiedEdit}
      />
      <UnidentifiedPaymentEditForm payment={payment} />
      <OrderMatchesTable
        columns={SUGGESTION_COLUMNS}
        data={suggestions ?? []}
        title="Matching suggestions"
      />
      <OrderMatchesTable
        columns={SEARCH_COLUMNS}
        data={orders ?? []}
        title="Search orders"
        onSearch={handleOrdersSearch}
      />
      <Divider />
      <Allow perm={Permission.queryUnidentifiedPaymentComments}>
        <Comments paymentType={PaymentType.Unidentified} payment={payment} />
      </Allow>
    </>
  ) : null;
};
