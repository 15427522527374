import { useMemo, useCallback, useState } from 'react';
import { Table, Button, Popover, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cns from 'classnames';
import { usePageData } from '@hooks/usePageData';
import { useAnalytics } from '@hooks/useAnalytics';
import { retailKibanaLogger } from '@utils/logger';
import { formatDateTime } from '@utils/date';
import {
  MARKETPLACES,
  POSSIBLE_PUBLISHING_STATUSES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import {
  PublishData,
  useHasPublishingBlockers,
  useIsPublished,
} from '../publishHooks';
import { renderTitle, renderDate } from '../functions';
import { usePublish, useUnpublish } from '../publishHooks';
import { Status, PublishStatusChecking } from './../index';
import cn from './../styles.less';
import { useWatch } from 'react-hook-form';
import { useAppForm } from '@hooks/useAppForm';

const { Column } = Table;

type MobileDeProps = {
  status: Status;
  setCommonPublishingStatus: PublishStatusChecking;
};

export const MobileDe = (props: MobileDeProps): JSX.Element | null => {
  const { status, setCommonPublishingStatus } = props;
  const { data } = usePageData();
  const track = useAnalytics();
  const hasPublishingBlockers = useHasPublishingBlockers();
  const isPublished = useIsPublished(MARKETPLACES.MOBILEDE_V2);
  const [isPublishing, publish] = usePublish(setCommonPublishingStatus);
  const [isUnpublishing, unpublish] = useUnpublish(setCommonPublishingStatus);
  const { control } = useAppForm();
  const exportItems = useWatch({
    control,
    name: 'adMgmt.exportOverview.exportItems',
  });
  const [accountId, setAccountId] = useState<string | null>(() => {
    const data = exportItems.find(
      (item) => item.marketplace.id.toUpperCase() === MARKETPLACES.MOBILEDE_V2,
    );
    const accountId =
      data?.exportListings?.listings?.sort((a, b) => {
        if (a.lastPublishedAt && b.lastPublishedAt) {
          return (
            new Date(b.lastPublishedAt).getTime() -
            new Date(a.lastPublishedAt).getTime()
          );
        }
        if (a.lastPublishedAt) {
          return -1;
        }
        if (b.lastPublishedAt) {
          return 1;
        }

        return 0;
      })[0]?.account.id ?? null;

    return accountId;
  });

  const [templateId, setTemplateId] = useState<string>(() => {
    const exportItem = exportItems.find(
      (item) => item.marketplace.id.toUpperCase() === MARKETPLACES.MOBILEDE_V2,
    );
    const previousTemplateId =
      exportItem?.exportListings?.listings?.[0]?.contentTemplate?.id;

    const defaultTemplateIdFromDict =
      data?.adMgmt.dictionaries.mobiledeV2?.marketplaceAccountOptions
        .find((account) => accountId === account.value)
        ?.contentTemplateOptions.find((template) => template.defaulted)?.id;

    return previousTemplateId ?? defaultTemplateIdFromDict ?? '';
  });

  const mobileDeExportData = useMemo(() => {
    const data = exportItems.find(
      (item) => item.marketplace.id.toUpperCase() === MARKETPLACES.MOBILEDE_V2,
    );
    const listing = data?.exportListings?.listings?.find(
      (listing) =>
        listing.publishingStatus === POSSIBLE_PUBLISHING_STATUSES.PUBLISHED,
    );
    return data
      ? {
          marketplace: data.marketplace,
          daysOnline: data.daysOnline,
          firstPublishedAt: data.firstPublishedAt,
          updatedAt: data.updatedAt,
          lastPublishingErrors: data.lastPublishingErrors,
          link: listing?.link,
          publishingStatus: data.publishingStatus,
          canPublish: data.canPublish,
        }
      : null;
  }, [exportItems]);

  const handleAccountIdChange = useCallback(
    (value: string) => {
      setAccountId(value);
      track({
        eventType: 'edit',
        fieldId: 'City',
        section: TRACKING_SECTION.EXPORT_OVERVIEW_CL,
        sectionCategory: TRACKING_SECTION_CATEGORY.CL,
      });
      const newDefaultTemplateId =
        data?.adMgmt.dictionaries.mobiledeV2?.marketplaceAccountOptions
          .find((account) => value === account.value)
          ?.contentTemplateOptions.find((template) => template.defaulted)?.id;

      setTemplateId(newDefaultTemplateId ?? '');
    },
    [data?.adMgmt.dictionaries.mobiledeV2?.marketplaceAccountOptions, track],
  );

  const marketplaceAccountOptions =
    data?.adMgmt.dictionaries?.mobiledeV2?.marketplaceAccountOptions ?? [];

  if (!mobileDeExportData) {
    return null;
  }

  const dataSource = [mobileDeExportData];

  const handlePublish = () => {
    if (accountId === null) {
      retailKibanaLogger.info('MobileDe::handlePublish -> accountId is null');

      return console.error('MobileDe::handlePublish -> accountId is null');
    }

    const data: PublishData = {
      marketplaceId: mobileDeExportData.marketplace.id,
      accountIds: [accountId],
      accountConfigs: [{ accountId: accountId, contentTemplateId: templateId }],
    };
    publish(data);
    track(
      {
        eventType: 'publish',
        fieldId: 'Regular publish',
        section: TRACKING_SECTION.EXPORT_OVERVIEW_CL,
        sectionCategory: TRACKING_SECTION_CATEGORY.CL,
      },
      data,
    );
  };

  const handleUnpublish = () => {
    if (accountId === null) {
      retailKibanaLogger.info('MobileDe::handleUnpublish -> accountId is null');

      return console.error('MobileDe::handleUnpublish -> accountId is null');
    }

    const data = {
      marketplaceId: mobileDeExportData.marketplace.id,
      accountIds: [accountId],
    };
    unpublish(data);
    track(
      {
        eventType: 'unpublish',
        fieldId: 'Regular unpublish',
        section: TRACKING_SECTION.EXPORT_OVERVIEW_CL,
        sectionCategory: TRACKING_SECTION_CATEGORY.CL,
      },
      data,
    );
  };

  const renderAccountSelect = () => {
    const { publishingStatus } = mobileDeExportData;
    const filteredOptions = marketplaceAccountOptions.map(
      ({ label, value }) => ({
        label,
        value,
      }),
    );
    return (
      <Select
        value={accountId}
        options={filteredOptions}
        data-qa-selector="mobiledeDealerId"
        placeholder="Choose the City"
        allowClear={true}
        disabled={
          isPublished ||
          status.isStatusChecking ||
          publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS
        }
        onChange={handleAccountIdChange}
      />
    );
  };

  const renderTemplateSelect = () => {
    const { publishingStatus } = mobileDeExportData;
    const currentAccount = marketplaceAccountOptions.find(
      (account) => account.value === accountId,
    );
    const filteredOptions =
      currentAccount?.contentTemplateOptions.map(({ label, id: value }) => ({
        label,
        value,
      })) ?? [];

    const defaultTemplateId: string =
      currentAccount?.contentTemplateOptions.find((option) => option.defaulted)
        ?.id ?? '';

    return (
      <Select
        value={templateId ?? defaultTemplateId}
        options={filteredOptions}
        data-qa-selector="mobiledeTemplateId"
        placeholder="Choose the Content Template"
        allowClear={true}
        disabled={
          isPublished ||
          status.isStatusChecking ||
          publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS ||
          !accountId
        }
        onChange={setTemplateId}
      />
    );
  };

  const renderStatus = () => {
    const { link, lastPublishingErrors, publishingStatus } = mobileDeExportData;

    if (
      (status.isStatusChecking &&
        status.platform.toUpperCase() === MARKETPLACES.MOBILEDE_V2) ||
      isPublishing ||
      isUnpublishing ||
      publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS
    ) {
      return (
        <div className={cn.status} data-qa-selector="mobilede-inProgress">
          In Progress ...
        </div>
      );
    }
    if (link) {
      return (
        <div className={cn.status}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            key={link.title}
            href={link.url}
            className={cn.stateItem}
            data-qa-selector="mobile.de-link"
          >
            {link.title}
          </a>
        </div>
      );
    }

    const hasErrors = !!(lastPublishingErrors && lastPublishingErrors.length);

    const publishingErrorTitle =
      lastPublishingErrors.length === 1
        ? `Last publishing error`
        : `Last ${lastPublishingErrors.length} publishing errors`;

    const lastErrors = lastPublishingErrors.map(
      ({ createdAt, message }, index) => (
        <div key={index}>
          {formatDateTime(createdAt)} | {message}
        </div>
      ),
    );

    return (
      <div className={cn.status}>
        {!isPublished && (
          <div
            key="notPublished"
            className={cn.stateItem}
            data-qa-selector="mobilede-notPublishedState"
          >
            Not Published
          </div>
        )}

        {hasErrors && (
          <Popover
            content={lastErrors}
            title={publishingErrorTitle}
            trigger="click"
            placement="bottom"
          >
            <div key="errors" className={cns(cn.stateItem, cn.hasErrors)}>
              {'Has errors '}
              <ExclamationCircleOutlined />
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const renderActionBtns = () => {
    const { publishingStatus, canPublish, marketplace } = mobileDeExportData;
    const isBtnDisabled =
      !accountId ||
      !canPublish ||
      hasPublishingBlockers ||
      status.isStatusChecking ||
      isPublishing ||
      isUnpublishing ||
      publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS;
    const isBtnTextUnknown =
      (status.isStatusChecking &&
        status.platform.toUpperCase() === MARKETPLACES.MOBILEDE_V2) ||
      isPublishing ||
      isUnpublishing ||
      publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS;

    if (isPublished) {
      return (
        <Button
          disabled={isBtnDisabled}
          className={cn.actionButton}
          data-qa-selector={`unpublish-${marketplace.id}`}
          type={status.isStatusChecking ? 'default' : 'primary'}
          onClick={handleUnpublish}
          style={
            status.isStatusChecking
              ? {}
              : { backgroundColor: '#ff7518', borderColor: 'transparent' }
          }
        >
          {isBtnTextUnknown ? '...' : 'Unpublish'}
        </Button>
      );
    } else {
      return (
        <Button
          disabled={isBtnDisabled}
          className={cn.actionButton}
          data-qa-selector={`publish-${marketplace.id}`}
          type={status.isStatusChecking ? 'default' : 'primary'}
          onClick={handlePublish}
        >
          {isBtnTextUnknown ? '...' : 'Publish'}
        </Button>
      );
    }
  };

  const renderActions = () => {
    return <div className={cn.actionWrapper}>{renderActionBtns()}</div>;
  };

  type ExportItem = typeof mobileDeExportData;

  return (
    <div className={cn.root}>
      <Table<ExportItem>
        data-qa-selector="mobilede-export-table"
        pagination={false}
        rowKey={(d) => d.marketplace.id}
        size="small"
        dataSource={dataSource}
        scroll={{
          x: true,
        }}
      >
        <Column<ExportItem>
          dataIndex="marketplace"
          title={() =>
            renderTitle({ key: 'marketplace', title: 'Marketplace' })
          }
          render={(marketplace) => (
            <span data-qa-selector="marketplace-value">
              {marketplace.title}
            </span>
          )}
          width="10%"
        />
        <Column<ExportItem>
          dataIndex="firstPublishedAt"
          title={() =>
            renderTitle({
              key: 'firstPublishedAt',
              title: 'First Published At',
            })
          }
          render={(date: string) => (
            <span data-qa-selector={'firstPublishedAt-value'}>
              {renderDate(date)}
            </span>
          )}
          width="10%"
        />
        <Column<ExportItem>
          dataIndex="updatedAt"
          title={() => renderTitle({ key: 'updatedAt', title: 'Updated At' })}
          render={(date: string) => (
            <span data-qa-selector={'updatedAt-value'}>{renderDate(date)}</span>
          )}
          width="10%"
        />
        <Column<ExportItem>
          dataIndex="daysOnline"
          title={() => renderTitle({ key: 'daysOnline', title: 'Days Online' })}
          render={(daysCount: number) => (
            <span data-qa-selector={'daysOnline-value'}>{daysCount}</span>
          )}
          width="8%"
        />
        <Column<ExportItem>
          dataIndex="publishingStatus"
          title={() => renderTitle({ key: 'publishingStatus', title: 'State' })}
          render={renderStatus}
          width="10%"
        />

        <Column<ExportItem>
          dataIndex="mobiledeDealerId"
          width={180}
          className={'cityCol'}
          title={() => renderTitle({ key: 'city', title: 'City' })}
          render={renderAccountSelect}
        />
        <Column<ExportItem>
          dataIndex="contentTemplate"
          width={180}
          className={'cityCol'}
          title={() =>
            renderTitle({ key: 'contentTemplate', title: 'Content Template' })
          }
          render={renderTemplateSelect}
        />
        <Column<ExportItem>
          dataIndex="_"
          title={() => renderTitle({ key: 'action', title: 'Action' })}
          render={renderActions}
          width="10%"
        />
      </Table>
    </div>
  );
};
