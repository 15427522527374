import { PaymentType } from "~/constants";
import { CommentFragment } from "~/graphql";

const createSuccessfulPaymentCommentMutation = /* GraphQL */ `
  mutation createPaymentComment($paymentId: UUID!, $value: String!) {
    comment: createRetailPaymentComment(
      paymentComment: { paymentId: $paymentId, value: $value }
    ) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

const createInternalPaymentCommentMutation = /* GraphQL */ `
  mutation createPaymentComment($paymentId: UUID!, $value: String!) {
    comment: createInternalTransferPaymentComment(
      paymentComment: { paymentId: $paymentId, value: $value }
    ) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

const createUnidentifiedPaymentCommentMutation = /* GraphQL */ `
  mutation createPaymentComment($paymentId: UUID!, $value: String!) {
    comment: createUnidentifiedPaymentComment(
      paymentComment: { paymentId: $paymentId, value: $value }
    ) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

export const createPaymentCommentMutationByType = {
  [PaymentType.Successful]: createSuccessfulPaymentCommentMutation,
  [PaymentType.Internal]: createInternalPaymentCommentMutation,
  [PaymentType.Unidentified]: createUnidentifiedPaymentCommentMutation,
};
