import { Divider } from "antd";

import { Allow, Comments } from "~/components";
import { Permission, PaymentType } from "~/constants";

import { routePathByPage } from "../../constants";
import { PaymentOverviewCard } from "../../components";
import { usePaymentDetails } from "../hooks";
import { PaymentHeader } from "../PaymentHeader";

export function UnidentifiedPaymentView() {
  const { data: payment } = usePaymentDetails(PaymentType.Unidentified);

  return payment ? (
    <>
      <PaymentHeader
        payment={payment}
        navigationPath={routePathByPage.unidentifiedView}
      />
      <PaymentOverviewCard mode="view" payment={payment} />
      <Divider />
      <Allow perm={Permission.queryUnidentifiedPaymentComments}>
        <Comments paymentType={PaymentType.Unidentified} payment={payment} />
      </Allow>
    </>
  ) : null;
}
