import type { CollapseProps } from 'antd';
import { App, Collapse, Button, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatServerError } from '@src/shared/formating/format-server-error';
// import { PhasesKeys } from './entities/ClusterCollapse/phasesTableConfig';
import {
  useGetDownPricingConfigsQuery,
  useUpdateDownPricingConfigsMutation,
  DownPricingCountryConfigInput,
  DownPricingDeltaConfigs,
  DownPricingPhaseConfigs,
  DownPricingDeltaConfigsInput,
  DownPricingPhaseConfigsInput,
} from '@gql_codegen/price-management-config-types';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';
import { ClusterCollapse } from './entities/ClusterCollapse/ClusterCollapse';
import { useDownPricingStore } from './store/downPricingStore';
import {
  unitsFromMinorToMajor,
  unitsFromMajorToMinor,
} from '@utils/convertMinorToMajorUnits';

export const DownPricingConfigs: React.FC = () => {
  const [queryData, setQueryData, getCountryData, resetIsEditedCountry] =
    useDownPricingStore((state) => [
      state.downpricingConfigsList,
      state.setQueryData,
      state.getCountryData,
      state.resetIsEditedCountry,
    ]);
  const { notification } = App.useApp();
  const [editedTracksCount, setEditedTracksCount] = useState(0);

  const { mutate, isPending } = useUpdateDownPricingConfigsMutation();

  const downPricingQuery = useConvertGeneratedQueryToSuspense(
    useGetDownPricingConfigsQuery,
    undefined,
    {
      select: (data) => {
        return {
          getDownPricingConfigs: data.getDownPricingConfigs.map((ctry) => ({
            country: ctry.country,
            priceClusterConfigs: ctry.priceClusterConfigs.map((cl) => ({
              priceCluster: cl.priceCluster,
              trackConfigs: cl.trackConfigs.map((tr) => ({
                track: tr.track,
                phaseConfigs: Object.fromEntries(
                  Object.entries(tr.phaseConfigs).map(([key, val]) => {
                    if (val !== 'DownPricingPhaseConfigs') {
                      return [
                        key,
                        {
                          ...val,
                          strategyConfigs: val.strategyConfigs.map((cfg) => {
                            return {
                              strategy: cfg.strategy,
                              deltaConfigs: Object.fromEntries(
                                Object.entries(cfg.deltaConfigs).map(
                                  ([k, d]) => {
                                    if (typeof d !== 'string') {
                                      return [
                                        k,
                                        'deltaAmount1' in d
                                          ? {
                                              deltaAmount1:
                                                unitsFromMinorToMajor(
                                                  d.deltaAmount1,
                                                ),
                                              deltaAmount2:
                                                unitsFromMinorToMajor(
                                                  d.deltaAmount2,
                                                ),
                                            }
                                          : {
                                              deltaPercentage:
                                                d.deltaPercentage,
                                            },
                                      ];
                                    } else {
                                      return [];
                                    }
                                  },
                                ),
                              ) as DownPricingDeltaConfigs,
                            };
                          }),
                        },
                      ];
                    } else {
                      return [];
                    }
                  }),
                ) as DownPricingPhaseConfigs,
              })),
            })),
          })),
        };
      },
    },
  );
  useEffect(() => {
    setQueryData(downPricingQuery.data.getDownPricingConfigs);
  }, [downPricingQuery.data.getDownPricingConfigs, setQueryData]);

  const handleCountrySave = useCallback(
    (country: string) => {
      const countryData = getCountryData(country);
      if (!countryData)
        return notification.error({ message: 'Country data not found' });

      const mutationInput: DownPricingCountryConfigInput = {
        country: countryData.country,
        priceClusterConfigs: countryData.priceClusterConfigs.map((cl) => {
          return {
            priceCluster: cl.priceCluster,
            trackConfigs: cl.trackConfigs.map((tr) => {
              return {
                track: tr.track,
                phaseConfigs: Object.fromEntries(
                  Object.entries(tr.phaseConfigs).map(([key, val]) => {
                    if (val !== 'DownPricingPhaseConfigs') {
                      return [
                        key,
                        {
                          ...val,
                          strategyConfigs: val.strategyConfigs.map((cfg) => {
                            return {
                              strategy: cfg.strategy,
                              deltaConfigs: Object.fromEntries(
                                Object.entries(cfg.deltaConfigs).map(
                                  ([k, d]) => {
                                    if (typeof d !== 'string') {
                                      return [
                                        k,
                                        'deltaPercentage' in d
                                          ? {
                                              percentage: {
                                                deltaPercentage:
                                                  d.deltaPercentage,
                                              },
                                            }
                                          : {
                                              carvana: {
                                                deltaAmount1:
                                                  unitsFromMajorToMinor(
                                                    d.deltaAmount1,
                                                  ),
                                                deltaAmount2:
                                                  unitsFromMajorToMinor(
                                                    d.deltaAmount2,
                                                  ),
                                              },
                                            },
                                      ];
                                    } else {
                                      return [];
                                    }
                                  },
                                ),
                              ) as DownPricingDeltaConfigsInput,
                            };
                          }),
                        },
                      ];
                    } else {
                      return [];
                    }
                  }),
                ) as DownPricingPhaseConfigsInput,
              };
            }),
          };
        }),
      };

      mutate(
        { input: mutationInput },
        {
          onSuccess: () => {
            resetIsEditedCountry(country);
            notification.success({
              message: 'Configuration saved successfully!',
            });
          },
          onError: (error) => {
            //handle error
            notification.error({ message: formatServerError(error) });
          },
        },
      );
    },
    [getCountryData, mutate, resetIsEditedCountry, notification],
  );

  const countryItems: CollapseProps['items'] = useMemo(
    () =>
      queryData.map((country, idx) => {
        const clusterItems = country.priceClusterConfigs.map((cluster, i) => {
          return {
            key: i,
            label: cluster.priceCluster,
            style: { background: cluster.isClusterEdited && '#ffff00' },
            ['data-qa-selector']: `clusterCollapseItem-${i}`,
            children: (
              <ClusterCollapse
                country={country.country}
                cluster={cluster}
                setEditedTracksCount={setEditedTracksCount}
              />
            ),
          };
        });
        return {
          key: idx,
          label: country.country,
          ['data-qa-selector']: `countryCollapseItem-${idx}`,
          children: <Collapse size="small" items={clusterItems} />,
          extra: country.isCountryEdited && (
            <>
              {isPending && <Spin />}{' '}
              <Button
                type="primary"
                onClick={(event) => {
                  // If you don't want click extra trigger collapse, you can prevent this:
                  event.stopPropagation();
                  handleCountrySave(country.country);
                }}
                disabled={isPending || editedTracksCount > 0}
                data-qa-selector="saveChangesButton"
              >
                Save changes
              </Button>
            </>
          ),
        };
      }),
    [queryData, isPending, editedTracksCount, handleCountrySave],
  );

  return (
    <>
      {/* <HStack justifyContent="space-between" className={css({ m: '15px 0' })}>
        <Popconfirm
          title="Warning"
          description={
            <>
              <div>
                Saving a new experiment track configuration will overwrite the
                existing data.
              </div>
              <div>
                This action cannot be undone. Are you sure you want to proceed?
              </div>
            </>
          }
          // onConfirm={() => {}}
          okText={
            <span data-qa-selector="createNewDownPricingConfigButtonProceed">
              Proceed
            </span>
          }
          cancelText={
            <span data-qa-selector="createNewDownPricingConfignButtonCancel">
              Cancel
            </span>
          }
        >
          <Button
            type="default"
            data-qa-selector="createNewDownPricingConfigButton"
          >
            <PlusOutlined /> Create new Donwpricing Config
          </Button>
        </Popconfirm>
      </HStack> */}

      <Collapse
        data-qa-selector="countryCollapseWrapper"
        items={countryItems}
      />
    </>
  );
};
