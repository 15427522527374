import {
  PaymentMethod,
  CreationSource,
  PaymentUploadStatus,
} from "~/constants";
import { formatAmount, formatDate, formatSource } from "~/utils";

import { Comment, SuccessfulPayment } from "./types";

type SuccessfulPaymentEntity = {
  id: string;
  importDetails?: {
    recipientBankAccount?: string;
    customerPaymentReference?: string;
  };
  accountDetails: {
    bic: string;
    holder: string;
    iban: string;
  };
  accountingDate?: string;
  amount: {
    amountMinorUnits: number;
    currencyCode: string;
  };
  countryCode: string;
  createdOn?: string;
  creationSource?: keyof typeof CreationSource;
  externalPaymentSystem?: unknown;
  externalId: string;
  orderId?: string;
  type: PaymentMethod;
  comments: Comment[];
};

export type SuccessfulPaymentResponse = {
  retailPayment: SuccessfulPaymentEntity;
  order: {
    orderNumber: string;
    stockNumber: string;
    customer: {
      firstName: string;
      lastName: string;
    };
  };
};

export const successfulPaymentAdapter = ({
  retailPayment: data,
  order: orderData,
}: SuccessfulPaymentResponse): SuccessfulPayment => ({
  id: data.id,
  createdOn: data.createdOn ? formatDate(data.createdOn, true) : "",
  accountingDate: data.accountingDate ? formatDate(data.accountingDate) : "",
  source: formatSource({
    creationSource: data.creationSource,
    hasExternalPaymentSystem: !!data.externalPaymentSystem,
  }),
  recipientBank: data.importDetails?.recipientBankAccount ?? "",
  customerReference: data.importDetails?.customerPaymentReference ?? "",
  paymentType: data.type,
  uploadStatus: PaymentUploadStatus.SUCCESSFUL,
  errorMessage: "",
  amount: formatAmount(
    data.amount.amountMinorUnits,
    data.amount.currencyCode,
    true
  ),
  accountHolderName: data.accountDetails?.holder,
  customerName: orderData.customer
    ? `${orderData?.customer?.firstName || ""} ${
        orderData?.customer?.lastName || ""
      }`
    : "-",
  bic: data.accountDetails?.bic,
  iban: data.accountDetails?.iban,
  orderId: orderData?.orderNumber,
  stockId: orderData?.stockNumber,
  retailCountry: data.countryCode,
  externalId: data.externalId,
  comments: data.comments,
});
