import { z } from 'zod';

const phaseSchema = z.object({
  minOnlineDays: z.number().int(),
  maxOnlineDays: z.number().int(),
  lagDays: z.number().int(),
  killSwitchDays: z.number().int(),
  ignoreOpenOpportunities: z.boolean(),
  targetPriceRatio: z.number().int(),
  strategyConfigs: z.array(
    z.object({
      strategy: z.string(),
      deltaConfigs: z.object({
        q1Config: z.object({ deltaPercentage: z.number().min(0) }).or(
          z.object({
            deltaAmount1: z
              .number()
              .int()
              .transform((x) => x * 100),
            deltaAmount2: z
              .number()
              .int()
              .transform((x) => x * 100),
          }),
        ),
        q2Config: z.object({ deltaPercentage: z.number().min(0) }).or(
          z.object({
            deltaAmount1: z.number().int(),
            deltaAmount2: z.number().int(),
          }),
        ),
        q3Config: z.object({ deltaPercentage: z.number().min(0) }).or(
          z.object({
            deltaAmount1: z.number().int(),
            deltaAmount2: z.number().int(),
          }),
        ),
        q4Config: z.object({ deltaPercentage: z.number().min(0) }).or(
          z.object({
            deltaAmount1: z.number().int(),
            deltaAmount2: z.number().int(),
          }),
        ),
      }),
    }),
  ),
});

export const DownpricingConfigSchema = z.object({
  fresh: phaseSchema,
  turnover1: phaseSchema,
  turnover2: phaseSchema,
  offloading: phaseSchema,
});

export type DownpricingConfigSchemaType = z.infer<
  typeof DownpricingConfigSchema
>;
