export const searchOrdersQuery = /* GraphQL */ `
  query ($search: SearchProjection2Input!) {
    searchOrders(search: $search) {
      entities {
        customer {
          firstName
          lastName
          name
        }
        customerCountry
        id
        orderNumber
        paymentDetails {
          paidAmount {
            amountMinorUnits
            currencyCode
          }
          remainingAmount {
            amountMinorUnits
            currencyCode
          }
        }
        retailCountry
        state
        stockNumber
      }
    }
  }
`;

export type OrderFragment = {
  customer?: {
    firstName?: string;
    lastName?: string;
    name?: string;
  };
  customerCountry?: string;
  id: string;
  orderNumber: string;
  paymentDetails: {
    paidAmount: {
      amountMinorUnits: number;
      currencyCode: string;
    };
    remainingAmount: {
      amountMinorUnits: number;
      currencyCode: string;
    };
  };
  retailCountry: string;
  state: string;
  stockNumber?: string;
};

export type SearchOrdersQueryData = {
  searchOrders?: {
    entities?: OrderFragment[];
  };
};
