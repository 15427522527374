import { CreationSource } from "~/constants";

export const formatSource = ({
  hasExternalPaymentSystem,
  creationSource,
}: {
  hasExternalPaymentSystem: boolean;
  creationSource?: keyof typeof CreationSource;
}) => {
  if (creationSource) {
    return CreationSource[creationSource];
  }

  return hasExternalPaymentSystem
    ? CreationSource.EXTERNAL_PAYMENT_SYSTEM
    : `${CreationSource.MANUAL_BATCH_IMPORTER} / ${CreationSource.MANUALLY_CREATED}`;
};
