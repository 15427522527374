import { useMemo } from "react";

import { Comment } from "~/adapters";
import { formatDate } from "~/utils";

export function useTableColumns() {
  return useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "createdOn",
        key: "createdOn",
        render: (createdOn: Comment["createdOn"]) =>
          formatDate(createdOn, true),
      },
      {
        title: "From",
        dataIndex: "createdBy",
        key: "createdBy",
        render: (createdBy: Comment["createdBy"]) =>
          `${createdBy.firstName} ${createdBy.lastName}`,
      },
      {
        title: "Comment",
        dataIndex: "value",
        key: "value",
      },
    ],
    []
  );
}
