import { Domain } from "~/constants";
import {
  successfulPaymentQuery,
  otherPaymentQuery,
  unidentifiedPaymentQuery,
} from "~/graphql";
import {
  successfulPaymentAdapter,
  otherPaymentAdapter,
  unidentifiedPaymentAdapter,
} from "~/adapters";

export const gqlConfig = {
  successful: {
    query: successfulPaymentQuery,
    domain: Domain.ORDER,
    adapter: successfulPaymentAdapter,
  },
  internal: {
    query: otherPaymentQuery,
    domain: Domain.PAYMENTS,
    adapter: otherPaymentAdapter,
  },
  unidentified: {
    query: unidentifiedPaymentQuery,
    domain: Domain.PAYMENTS,
    adapter: unidentifiedPaymentAdapter,
  },
};
