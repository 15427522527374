import {
  PaymentMethod,
  OtherPaymentCategory,
  CreationSource,
} from "~/constants";
import { formatAmount, formatDate, formatSource } from "~/utils";

import { Comment, OtherPayment } from "./types";

type FetchOtherPaymentResponse = {
  internalTransferPaymentById: {
    id: string;
    importDetails?: {
      recipientBankAccount?: string;
      customerPaymentReference?: string;
    };
    accountData: {
      bic: string;
      holder: string;
      iban: string;
    };
    accountingDate: string;
    amount: {
      amountMinorUnits: number;
      currencyCode: string;
    };
    category: OtherPaymentCategory;
    countryCode: string;
    createdOn: string;
    externalId: string;
    type: PaymentMethod;
    creationSource?: keyof typeof CreationSource;
    comments: Comment[];
  };
};

export const otherPaymentAdapter = ({
  internalTransferPaymentById: data,
}: FetchOtherPaymentResponse): OtherPayment => ({
  id: data.id,
  createdOn: formatDate(data.createdOn, true),
  accountingDate: formatDate(data.accountingDate),
  source: formatSource({
    creationSource: data.creationSource,
    hasExternalPaymentSystem: false,
  }),
  recipientBank: data.importDetails?.recipientBankAccount ?? "",
  customerReference: data.importDetails?.customerPaymentReference ?? "",
  paymentType: data.type,
  errorMessage: "",
  amount: formatAmount(
    data.amount.amountMinorUnits,
    data.amount.currencyCode,
    true
  ),
  accountHolderName: data.accountData?.holder,
  bic: data.accountData?.bic,
  iban: data.accountData?.iban,
  retailCountry: data.countryCode,
  externalId: data.externalId,
  category: data.category,
  comments: data.comments,
});
