import { ColumnConfig } from './types';

export enum OrderOverviewColumnName {
  CreatedAt = 'createdAt',
  ContractSignedOn = 'contractSignedOn',
  PendingVerificationOn = 'pendingVerificationOn',
  SalesAgentAssignedTo = 'salesAgentAssignedTo',
  OrderNumber = 'orderNumber',
  StockNumber = 'stockNumber',
  Customer = 'customer',
  State = 'state',
  PaymentType = 'paymentType',
  FinancingStatus = 'financing.status',
  PaymentStatus = 'paymentStatus',
  TotalPriceGross = 'totalPriceGross',
  OverdueBranchDelivery = 'overdueBranchDelivery',
  OverdueMaxEta = 'overdueMaxEta',
  Id = 'id',
  VerifiedOn = 'verifiedOn',
  DeliveredOn = 'deliveredOn',
  CancellationRequested = 'cancellationRequested',
  CancellationType = 'cancelType',
  CancellationPeriod = 'cancelPeriod',
  CancellationReason = 'cancelReason',
  CancellationSubReason = 'subCancelReason',
  LicensePlate = 'licensePlate',
  VIN = 'vin',
  FinanceFlowType = 'financing.flowType',
  TradeIn = 'tradeIn',
  CarRetailReady = 'carRetailReady',
  ExitCheckCompleted = 'exitCheckState',
  HandoverAppointmentSet = 'handoverDateSet',
  CarInBranch = 'carInBranch',
  DocumentRequests = 'documentRequests',
  DeliveryOption = 'deliveryOption',
  OpportunityId = 'opportunity.id',
  OrdersOnStock = 'ordersOnStock',
  CanceledOn = 'canceledOn',
  Type = 'type',
  ConversionType = 'conversionType',
  CustomerType = 'customer.customerType',
  Priority = 'priority',
  Warranty = 'warranty',
  PPI = 'financing.ppi',
  GAP = 'financing.gap',
  DaysInInventory = 'daysInInventory',
  SecondaryWheelsAdded = 'secondaryWheelsAdded'
}

export const ORDER_OVERVIEW_COLUMN_NAME_TO_SORT: Record<string, string> = {
  [OrderOverviewColumnName.TotalPriceGross]: 'totalPriceGross.amountMinorUnits',
  [OrderOverviewColumnName.State]: 'bo_standard_order_state'
};

export const ORDER_OVERVIEW_COLUMN_NAME_TO_TRANSLATION_KEY = {
  [OrderOverviewColumnName.CreatedAt]:
    'bo.orderOverview.table.title.orderCreated',
  [OrderOverviewColumnName.ContractSignedOn]:
    'bo.orderOverview.table.title.contractSigned',
  [OrderOverviewColumnName.PendingVerificationOn]:
    'bo.orderOverview.table.title.pendingVerification',
  [OrderOverviewColumnName.SalesAgentAssignedTo]:
    'bo.orderOverview.searchForm.field.salesAgentAssignedTo',
  [OrderOverviewColumnName.OrderNumber]: 'bo.orderOverview.table.title.orderNo',
  [OrderOverviewColumnName.StockNumber]: 'bo.orderOverview.table.title.stockNo',
  [OrderOverviewColumnName.Customer]:
    'bo.orderOverview.table.title.customerName',
  [OrderOverviewColumnName.State]: 'bo.orderOverview.table.title.orderState',
  [OrderOverviewColumnName.PaymentType]:
    'bo.orderOverview.searchForm.field.paymentType',
  [OrderOverviewColumnName.FinancingStatus]:
    'bo.orderOverview.searchForm.field.financeApplicationStatus',
  [OrderOverviewColumnName.PaymentStatus]:
    'bo.orderOverview.table.title.paymentStatus',
  [OrderOverviewColumnName.TotalPriceGross]:
    'bo.orderOverview.table.title.orderAmount',
  [OrderOverviewColumnName.OverdueBranchDelivery]:
    'bo.orderOverview.table.title.overdueBranchDelivery',
  [OrderOverviewColumnName.OverdueMaxEta]:
    'bo.orderOverview.table.title.overdueDeliveryEta',
  [OrderOverviewColumnName.VerifiedOn]:
    'bo.orderOverview.searchForm.field.verifiedOn',
  [OrderOverviewColumnName.DeliveredOn]:
    'bo.orderOverview.searchForm.field.deliveredOn',
  [OrderOverviewColumnName.CancellationRequested]:
    'bo.orderOverview.searchForm.field.cancellationRequested',
  [OrderOverviewColumnName.CancellationType]:
    'bo.orderOverview.searchForm.field.cancellationType',
  [OrderOverviewColumnName.CancellationPeriod]:
    'bo.orderOverview.searchForm.field.cancellationPeriod',
  [OrderOverviewColumnName.CancellationReason]:
    'bo.orderOverview.searchForm.field.cancellationReason',
  [OrderOverviewColumnName.CancellationSubReason]:
    'bo.orderOverview.searchForm.field.cancellationSubReason',
  [OrderOverviewColumnName.LicensePlate]:
    'bo.orderOverview.searchForm.field.licensePlate',
  [OrderOverviewColumnName.VIN]: 'bo.orderOverview.searchForm.field.VIN',
  [OrderOverviewColumnName.FinanceFlowType]:
    'bo.orderOverview.searchForm.field.financeFlowType',
  [OrderOverviewColumnName.TradeIn]:
    'bo.orderOverview.searchForm.field.tradeIn',
  [OrderOverviewColumnName.CarRetailReady]:
    'bo.orderOverview.searchForm.field.carRetailReady',
  [OrderOverviewColumnName.ExitCheckCompleted]:
    'bo.orderOverview.searchForm.field.exitCheckState',
  [OrderOverviewColumnName.HandoverAppointmentSet]:
    'bo.orderOverview.searchForm.field.handoverDateSet',
  [OrderOverviewColumnName.CarInBranch]:
    'bo.orderOverview.searchForm.field.carInBranch',
  [OrderOverviewColumnName.DeliveryOption]:
    'bo.orderOverview.searchForm.field.deliveryOption',
  [OrderOverviewColumnName.OpportunityId]:
    'bo.orderOverview.searchForm.field.opportunityId',
  [OrderOverviewColumnName.OrdersOnStock]:
    'bo.orderOverview.searchForm.field.ordersOnStock',
  [OrderOverviewColumnName.CanceledOn]:
    'bo.orderOverview.searchForm.field.canceledOn',
  [OrderOverviewColumnName.Type]: 'bo.orderOverview.searchForm.field.type',
  [OrderOverviewColumnName.ConversionType]:
    'bo.orderOverview.searchForm.field.conversionType',
  [OrderOverviewColumnName.CustomerType]:
    'bo.orderOverview.searchForm.field.customerType',
  [OrderOverviewColumnName.Priority]:
    'bo.orderOverview.searchForm.field.priority',
  [OrderOverviewColumnName.Warranty]:
    'bo.orderOverview.searchForm.field.warranty',
  [OrderOverviewColumnName.PPI]: 'bo.orderOverview.searchForm.field.ppi',
  [OrderOverviewColumnName.GAP]: 'bo.orderOverview.searchForm.field.gap',
  [OrderOverviewColumnName.DaysInInventory]:
    'bo.orderOverview.searchForm.field.daysInInventory',
  [OrderOverviewColumnName.SecondaryWheelsAdded]:
    'bo.orderOverview.searchForm.field.secondaryWheelsAdded'
};

export const DEFAULT_ORDER_OVERVIEW_COLUMN_CONFIG: ColumnConfig[] = [
  { name: OrderOverviewColumnName.CreatedAt, isVisible: true },
  { name: OrderOverviewColumnName.ContractSignedOn, isVisible: true },
  { name: OrderOverviewColumnName.PendingVerificationOn, isVisible: true },
  { name: OrderOverviewColumnName.VerifiedOn, isVisible: true },
  { name: OrderOverviewColumnName.DeliveredOn, isVisible: false },
  { name: OrderOverviewColumnName.CancellationRequested, isVisible: false },
  { name: OrderOverviewColumnName.CanceledOn, isVisible: false },
  { name: OrderOverviewColumnName.CancellationType, isVisible: false },
  { name: OrderOverviewColumnName.CancellationPeriod, isVisible: false },
  { name: OrderOverviewColumnName.CancellationReason, isVisible: false },
  { name: OrderOverviewColumnName.CancellationSubReason, isVisible: false },
  { name: OrderOverviewColumnName.SalesAgentAssignedTo, isVisible: true },
  { name: OrderOverviewColumnName.OrderNumber, isVisible: true },
  { name: OrderOverviewColumnName.Type, isVisible: false },
  { name: OrderOverviewColumnName.ConversionType, isVisible: false },
  { name: OrderOverviewColumnName.StockNumber, isVisible: true },
  { name: OrderOverviewColumnName.LicensePlate, isVisible: false },
  { name: OrderOverviewColumnName.VIN, isVisible: false },
  { name: OrderOverviewColumnName.OpportunityId, isVisible: false },
  { name: OrderOverviewColumnName.Priority, isVisible: false },
  { name: OrderOverviewColumnName.OrdersOnStock, isVisible: false },
  { name: OrderOverviewColumnName.CustomerType, isVisible: false },
  { name: OrderOverviewColumnName.Customer, isVisible: true },
  { name: OrderOverviewColumnName.State, isVisible: true },
  { name: OrderOverviewColumnName.PaymentType, isVisible: true },
  { name: OrderOverviewColumnName.FinancingStatus, isVisible: true },
  { name: OrderOverviewColumnName.FinanceFlowType, isVisible: false },
  { name: OrderOverviewColumnName.PaymentStatus, isVisible: true },
  { name: OrderOverviewColumnName.TotalPriceGross, isVisible: true },
  { name: OrderOverviewColumnName.TradeIn, isVisible: false },
  { name: OrderOverviewColumnName.CarRetailReady, isVisible: false },
  { name: OrderOverviewColumnName.ExitCheckCompleted, isVisible: false },
  { name: OrderOverviewColumnName.HandoverAppointmentSet, isVisible: false },
  { name: OrderOverviewColumnName.CarInBranch, isVisible: false },
  { name: OrderOverviewColumnName.DeliveryOption, isVisible: false },
  { name: OrderOverviewColumnName.OverdueBranchDelivery, isVisible: false },
  { name: OrderOverviewColumnName.OverdueMaxEta, isVisible: false },
  { name: OrderOverviewColumnName.Warranty, isVisible: false },
  { name: OrderOverviewColumnName.PPI, isVisible: false },
  { name: OrderOverviewColumnName.GAP, isVisible: false },
  { name: OrderOverviewColumnName.DaysInInventory, isVisible: false },
  { name: OrderOverviewColumnName.SecondaryWheelsAdded, isVisible: false }
].map((x, order) => ({
  ...x,
  order
}));
