export enum OtherPaymentCategory {
  Chargeback = "CHARGEBACK",
  ChequePaymentsBatch = "CHEQUE_PAYMENTS_BATCH",
  DuplicateFinancingAmount = "DUPLICATE_FINANCING_AMOUNT",
  FinancingKickback = "FINANCING_KICKBACK",
  Internal = "INTERNAL",
  Legal = "LEGAL",
  Logistic = "LOGISTIC",
  NotApplicable = "NOT_APPLICABLE_PAYMENT",
  OnHold = "ON_HOLD",
  OutgoingRefund = "OUTGOING_REFUND",
  Overpayment = "OVERPAYMENT",
  PenaltyFees = "PENALTY_FEES",
  RatenkaufFinancing = "RATENKAUF_FINANCING",
  Refund = "REFUND",
  RejectedOutbound = "REJECTED_OUTBOUND_PAYMENT",
  Test = "TEST",
}
