import { TableCell } from '@retail/backoffice-ui';
import { TableColumnType } from 'antd';
import { TFunction } from 'react-i18next';

import { OrderNumber } from '~/components/OrderNumber';
import { RetailCustomer } from '~/components/RetailCustomer';
import { StockNumber } from '~/components/StockNumber';
import { OrderState } from '~/constants/OrderState';
import { EsOrderModel } from '~/types/EsOrderModel';

import { Actions } from '../Actions';
import { OrderStateCell } from '../OrderStateCell';
import { YesNoCell } from '../YesNoCell';
import { OrderOverviewColumnName } from '../constants';
import { CONVENTION_TYPE_KEY_PREFIX } from '../hooks/useConversionTypeOptions';
import { CUSTOMER_TYPE_KEY_PREFIX } from '../hooks/useCustomerTypeOptions';
import { ORDER_TYPE_KEY_PREFIX } from '../hooks/useOrderTypeOptions';

interface ColumnNameToConfigProps {
  t: TFunction<'translation', undefined>;
}

export const COLUMN_NAME_TO_CONFIG: Record<
  string,
  (x: ColumnNameToConfigProps) => TableColumnType<EsOrderModel>
> = {
  [OrderOverviewColumnName.CarRetailReady]: () => ({
    dataIndex: OrderOverviewColumnName.CarRetailReady,
    sorter: true,
    render: (value) => (
      <YesNoCell
        data-qa-selector={OrderOverviewColumnName.CarRetailReady}
        value={value}
      />
    )
  }),
  [OrderOverviewColumnName.ExitCheckCompleted]: () => ({
    dataIndex: OrderOverviewColumnName.ExitCheckCompleted,
    sorter: true,
    render: (value) => (
      <YesNoCell
        data-qa-selector={OrderOverviewColumnName.ExitCheckCompleted}
        value={value}
      />
    )
  }),
  [OrderOverviewColumnName.HandoverAppointmentSet]: () => ({
    dataIndex: OrderOverviewColumnName.HandoverAppointmentSet,
    sorter: true,
    render: (value) => (
      <YesNoCell
        data-qa-selector={OrderOverviewColumnName.HandoverAppointmentSet}
        value={value}
      />
    )
  }),
  [OrderOverviewColumnName.CarInBranch]: () => ({
    dataIndex: OrderOverviewColumnName.CarInBranch,
    sorter: true,
    render: (value) => (
      <YesNoCell
        data-qa-selector={OrderOverviewColumnName.CarInBranch}
        value={value}
      />
    )
  }),
  [OrderOverviewColumnName.DeliveryOption]: () => ({
    dataIndex: OrderOverviewColumnName.DeliveryOption,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.DeliveryOption}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.VerifiedOn]: () => ({
    dataIndex: OrderOverviewColumnName.VerifiedOn,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.VerifiedOn}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.DeliveredOn]: () => ({
    dataIndex: OrderOverviewColumnName.DeliveredOn,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.DeliveredOn}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.CancellationRequested]: () => ({
    dataIndex: OrderOverviewColumnName.CancellationRequested,
    render: (_, { state, cancelRequestSubReason }) => (
      <YesNoCell
        data-qa-selector={OrderOverviewColumnName.CancellationRequested}
        value={state !== OrderState.CANCELED && cancelRequestSubReason != null}
      />
    )
  }),
  [OrderOverviewColumnName.CancellationType]: () => ({
    dataIndex: OrderOverviewColumnName.CancellationType,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.CancellationType}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.CancellationPeriod]: () => ({
    dataIndex: OrderOverviewColumnName.CancellationPeriod,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.CancellationPeriod}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.CancellationReason]: () => ({
    dataIndex: OrderOverviewColumnName.CancellationReason,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.CancellationReason}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.CancellationSubReason]: () => ({
    dataIndex: OrderOverviewColumnName.CancellationSubReason,
    sorter: true,
    render: (value) => (
      <TableCell
        data-qa-selector={OrderOverviewColumnName.CancellationSubReason}
      >
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.LicensePlate]: () => ({
    dataIndex: OrderOverviewColumnName.LicensePlate,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.LicensePlate}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.VIN]: () => ({
    dataIndex: OrderOverviewColumnName.VIN,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.VIN}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.FinanceFlowType]: () => ({
    dataIndex: OrderOverviewColumnName.FinanceFlowType,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.FinanceFlowType}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.TradeIn]: () => ({
    dataIndex: OrderOverviewColumnName.TradeIn,
    sorter: true,
    render: (value) => (
      <YesNoCell
        data-qa-selector={OrderOverviewColumnName.TradeIn}
        value={value}
      />
    )
  }),
  [OrderOverviewColumnName.CreatedAt]: () => ({
    dataIndex: OrderOverviewColumnName.CreatedAt,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.CreatedAt}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.ContractSignedOn]: () => ({
    dataIndex: OrderOverviewColumnName.ContractSignedOn,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.ContractSignedOn}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.PendingVerificationOn]: () => ({
    dataIndex: OrderOverviewColumnName.PendingVerificationOn,
    sorter: true,
    render: (value) => (
      <TableCell
        data-qa-selector={OrderOverviewColumnName.PendingVerificationOn}
      >
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.SalesAgentAssignedTo]: () => ({
    dataIndex: OrderOverviewColumnName.SalesAgentAssignedTo,
    sorter: true,
    render: (value) => (
      <TableCell
        data-qa-selector={OrderOverviewColumnName.SalesAgentAssignedTo}
      >
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.OrderNumber]: () => ({
    dataIndex: OrderOverviewColumnName.OrderNumber,
    render: (value, { flowVersion, id }) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.OrderNumber}>
        <OrderNumber
          flowVersion={flowVersion}
          orderNumber={value}
          orderId={id}
        />
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.StockNumber]: () => ({
    dataIndex: OrderOverviewColumnName.StockNumber,
    render: (stockNumber, { adId }) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.StockNumber}>
        {stockNumber && adId ? (
          <StockNumber adId={adId} stockNumber={stockNumber} />
        ) : null}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.Customer]: () => ({
    dataIndex: OrderOverviewColumnName.Customer,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.Customer}>
        <RetailCustomer value={value} />
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.State]: () => ({
    dataIndex: OrderOverviewColumnName.State,
    sorter: true,
    render: (
      value,
      {
        cancelType,
        cancelPeriod,
        cancelReason,
        subCancelReason,
        cancelDescription,
        stateColorCoding,
        documentRequests
      }
    ) => (
      <OrderStateCell
        value={value}
        cancelType={cancelType}
        cancelPeriod={cancelPeriod}
        cancelReason={cancelReason}
        subCancelReason={subCancelReason}
        cancelDescription={cancelDescription}
        stateColorCoding={stateColorCoding}
        documentRequests={documentRequests}
      />
    )
  }),
  [OrderOverviewColumnName.PaymentType]: ({ t }) => ({
    dataIndex: OrderOverviewColumnName.PaymentType,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.PaymentType}>
        {value ? t(`bo.taskManagement.paymentTypes.${value}`) : null}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.FinancingStatus]: ({ t }) => ({
    dataIndex: OrderOverviewColumnName.FinancingStatus,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.FinancingStatus}>
        {value ? t(`bo.taskManagement.financingStatuses.${value}`) : null}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.PaymentStatus]: () => ({
    dataIndex: OrderOverviewColumnName.PaymentStatus,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.PaymentStatus}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.TotalPriceGross]: () => ({
    dataIndex: OrderOverviewColumnName.TotalPriceGross,
    sorter: true,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.TotalPriceGross}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.OverdueBranchDelivery]: () => ({
    dataIndex: OrderOverviewColumnName.OverdueBranchDelivery,
    render: (value) => (
      <TableCell
        data-qa-selector={OrderOverviewColumnName.OverdueBranchDelivery}
      >
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.OverdueMaxEta]: () => ({
    dataIndex: OrderOverviewColumnName.OverdueMaxEta,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.OverdueMaxEta}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.OpportunityId]: () => ({
    dataIndex: OrderOverviewColumnName.OpportunityId,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.OpportunityId}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.OrdersOnStock]: () => ({
    dataIndex: OrderOverviewColumnName.OrdersOnStock,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.OrdersOnStock}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.CanceledOn]: () => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.CanceledOn,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.CanceledOn}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.Type]: ({ t }) => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.Type,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.Type}>
        {value ? t(`${ORDER_TYPE_KEY_PREFIX}.${value}`) : null}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.ConversionType]: ({ t }) => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.ConversionType,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.ConversionType}>
        {value ? t(`${CONVENTION_TYPE_KEY_PREFIX}.${value}`) : null}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.CustomerType]: ({ t }) => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.CustomerType,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.CustomerType}>
        {value ? t(`${CUSTOMER_TYPE_KEY_PREFIX}.${value}`) : null}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.Priority]: () => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.Priority,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.Priority}>
        {value}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.Warranty]: ({ t }) => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.Warranty,
    render: (_, { warranty }) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.Warranty}>
        {warranty?.level
          ? t(`bo.orderOverview.searchForm.field.warranty.${warranty?.level}`, {
              monthsCovered: warranty?.monthsCovered
            })
          : null}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.PPI]: () => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.PPI,
    render: (value) => (
      <YesNoCell data-qa-selector={OrderOverviewColumnName.PPI} value={value} />
    )
  }),
  [OrderOverviewColumnName.GAP]: () => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.GAP,
    render: (value) => (
      <YesNoCell data-qa-selector={OrderOverviewColumnName.GAP} value={value} />
    )
  }),
  [OrderOverviewColumnName.DaysInInventory]: () => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.DaysInInventory,
    render: (value) => (
      <TableCell data-qa-selector={OrderOverviewColumnName.DaysInInventory}>
        {Number.isFinite(value) ? value.toString() : null}
      </TableCell>
    )
  }),
  [OrderOverviewColumnName.SecondaryWheelsAdded]: () => ({
    sorter: true,
    dataIndex: OrderOverviewColumnName.SecondaryWheelsAdded,
    render: (value) => (
      <YesNoCell
        data-qa-selector={OrderOverviewColumnName.SecondaryWheelsAdded}
        value={value}
      />
    )
  }),
  [OrderOverviewColumnName.Id]: () => ({
    dataIndex: OrderOverviewColumnName.Id,
    width: 57,
    render: (value) => <Actions orderId={value} />
  })
};
