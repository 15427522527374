export const updateRetailUnidentifiedPayment = /* GraphQL */ `
  mutation (
    $payment: RetailUnidentifiedPaymentUpdateProjectionInput!
    $id: UUID!
  ) {
    updateRetailUnidentifiedPayment(unidentifiedPayment: $payment, id: $id) {
      id
    }
  }
`;
