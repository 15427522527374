import type { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Space, Typography } from 'antd';
import { OverviewPage } from '@features/OverviewPage';

const RouterRootComponent = () => (
  <Space direction="vertical" size="large" style={{ width: '100%' }}>
    <Typography.Title data-qa-selector="pageMainHeader">
      Pricing Configurations
    </Typography.Title>
    <OverviewPage />
    <Outlet />
    {import.meta.env.DEV && (
      <>
        <TanStackRouterDevtools position="bottom-left" />
        <ReactQueryDevtools buttonPosition="bottom-right" />
      </>
    )}
  </Space>
);
export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RouterRootComponent,
});
