import { useMutation, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { message } from "antd";

import { UnidentifiedPayment } from "~/adapters";
import { PaymentType, routePathByPage, Domain } from "~/constants";
import { transitionPaymentInternalTransferToUnidentified } from "~/graphql";
import { callGql } from "~/utils/apiClient";

export function useMarkAsUnidentified() {
  const queryClient = useQueryClient();
  const { lang } = useParams<{ lang: string }>();
  const history = useHistory();

  return useMutation({
    async mutationFn(paymentId: string) {
      const { data, errors } = await callGql({
        query: transitionPaymentInternalTransferToUnidentified,
        variables: {
          ids: {
            entities: [paymentId],
          },
        },
        domain: Domain.PAYMENTS,
      });
      if (errors?.length) {
        throw errors[0]?.message;
      }
      return data?.transitionPaymentInternalTransferToUnidentified?.[0];
    },
    onSuccess(unidentifiedPayment: UnidentifiedPayment) {
      queryClient.refetchQueries(["payments"]);

      history.push({
        pathname: generatePath(routePathByPage.unidentifiedView, {
          lang,
          paymentType: PaymentType.Unidentified,
          id: unidentifiedPayment.id,
        }),
        search: new URLSearchParams({
          externalId: unidentifiedPayment.externalId,
          page: "1",
        }).toString(),
        state: {
          filter: {
            paymentType: PaymentType.Unidentified,
          },
        },
      });

      message.success("Success");
    },
  });
}
