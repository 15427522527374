import { Form, Input, Space, Button } from "antd";
import { useCallback } from "react";

import { Comment } from "~/adapters";
import { PaymentType } from "~/constants";

import { useCreateCommentMutation } from "./hooks";

interface CommentFormProps {
  paymentType: PaymentType;
  paymentId: string;
  commentsSize: number;
  onSuccess: (comment: Comment) => void;
}

export function CommentForm({
  paymentType,
  paymentId,
  commentsSize,
  onSuccess,
}: CommentFormProps) {
  const [form] = Form.useForm();
  const { isLoading, mutate } = useCreateCommentMutation({
    paymentType,
    paymentId,
    onSuccess: (newComment) => {
      onSuccess(newComment);
      form.resetFields();
    },
  });
  const handleCancel = useCallback(() => {
    form.resetFields();
  }, [form]);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      disabled={isLoading}
      onFinish={mutate}
    >
      <Form.Item
        label={`Comments (${commentsSize})`}
        name="comment"
        rules={[{ required: true }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Space size="small">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
