export enum PaymentMethod {
  WireTransferCustomer = "WIRE_TRANSFER_CUSTOMER",
  WireTransferBankFinance = "WIRE_TRANSFER_BANK_FINANCE",
  OnlineCreditCard = "ONLINE_CREDIT_CARD",
  OnlineBankTransfer = "ONLINE_BANK_TRANSFER",
  OnlinePaypal = "ONLINE_PAYPAL",
  ManuallyCreated = "MANUALLY_CREATED",
  TradeIn = "TRADE_IN",
  OnlineDebitCard = "ONLINE_DEBIT_CARD",
}
