export const markUnidentifiedPaymentAsOtherMutation = /* GraphQL */ `
  mutation ($id: UUID!, $category: String!) {
    transitionPaymentUnidentifiedToInternalTransfer(
      id: $id
      category: $category
    ) {
      id
      externalId
    }
  }
`;
