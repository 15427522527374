import { checkIfFailResponse } from '@gql_codegen/api';
import { FAIL_RESPONSE } from '@gql_codegen/typings/api/api_fail';
import { AxiosError } from 'axios';

export const formatServerError = (
  error: FAIL_RESPONSE | AxiosError | null | Error,
): string | undefined => {
  if (error === null) return;

  if (checkIfFailResponse(error))
    return error.errors.map((error) => error.message).join('\n');

  if (error instanceof AxiosError || error instanceof Error)
    return error.message;

  return 'Unknown error';
};
