export const attachOrderMutation = /* GraphQL */ `
  mutation ($unidentifiedPaymentId: UUID!, $orderId: UUID!) {
    assignUnidentifiedPaymentToOrder(
      unidentifiedPaymentId: $unidentifiedPaymentId
      orderId: $orderId
    ) {
      externalId
      id
    }
  }
`;
